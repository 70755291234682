import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProtectedRoutes = (props) => {
    const {Component} = props;
    const navigate = useNavigate()

    useEffect(() => {
      const login = localStorage.getItem('access_token')
      if (!login) {
        navigate('/login')
      }
    }, [navigate])

  return (
    <div>
      <Component/>
    </div>
  )
}

export default ProtectedRoutes
