import React, { useEffect } from 'react'
import DataTable from '../../shared/DataGrid'
import { CurrentStatusColumn, FrequentRequestColumn } from '../../utills/tableFields'

import SearchIcon from '../../assets/input-search-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryListWithPagination } from '../../services/category'
import { getAllCurrentRequestList } from '../../services/request'
import { getRequestAdmin } from '../../services/adminRequest'
import { useNavigate } from 'react-router'

const RequestCard = ({ borderColor, backgroundColor, count, label, navigate }) => (
    <div style={{ border: `1px solid ${borderColor}`, background: backgroundColor, height: '200px', cursor: 'pointer' }}
        onClick={() => navigate(`/agentrequest?status=${label}`)}

    >
        <div className="flex flex-col items-start justify-end h-full gap-3 px-3 py-4">
            <p className="text-[36px]">{count}</p>
            <p className='capitalize'>{label} Request</p>
        </div>
    </div>
);

export default function CommonDashboard() {
    const dispatch = useDispatch()
    const categoryData = useSelector((state) => state.categoryInfo.data.data);
    const currentRequestData = useSelector((state) => state.requestInfo?.data?.result?.data);
    const requestData = useSelector((state) => state.admin.details);
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getCategoryListWithPagination())
        dispatch(getAllCurrentRequestList())
        dispatch(getRequestAdmin());

    }, [])


    const colors = [
        { borderColor: 'rgba(23, 144, 11, 1)', backgroundColor: 'rgba(241, 255, 239, 1)' },
        { borderColor: 'rgba(255, 213, 0, 1)', backgroundColor: 'rgba(254, 255, 217, 1)' },
        { borderColor: 'rgba(255, 123, 0, 1)', backgroundColor: 'rgba(255, 245, 231, 1)' },
    ];
    return (
        <div className='px-4 lg:px-6 py-2.5'>
            <div className='flex items-center my-4 gap-9'>
                <p className='text-primary-blue text-[22px] font-semibold'>Good Morning, John</p>
                <p className='flex gap-3'>
                    <span style={{ borderRight: '1px solid rgba(0, 0, 0, 1)', paddingRight: '10px' }}>09.29 AM </span>
                    <span>09-08-2024 </span>
                </p>
            </div>

            <div>
                <div className="grid grid-cols-7 gap-5 py-5">
                    <div className="col-span-3">
                        <div className='px-2 py-3 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                            <div className='grid grid-cols-2 gap-2'>
                                <div style={{ border: '1px solid rgba(28, 101, 243, 1)', background: 'rgba(236, 242, 255, 1)', height: '200px' }}>
                                    <div className='flex flex-col items-start justify-end h-full gap-3 px-3 py-4 cursor-pointer'
                                        onClick={() => navigate(`/agentrequest`)}

                                    >
                                        <p className='text-[36px]'>{requestData?.total_request || 0}</p>
                                        <p>Total Request</p>
                                    </div>

                                </div>
                                {requestData?.requests?.map((card, index) => (
                                    <RequestCard
                                        key={index}
                                        borderColor={colors[index % colors.length].borderColor}
                                        backgroundColor={colors[index % colors.length].backgroundColor}
                                        count={card.request_count}
                                        label={card.request_via}
                                        navigate={navigate}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className='px-6 py-2 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                            <p className='px-3 py-3 text-[20px] mb-3' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>Frequently Requested Categories</p>

                            <DataTable rows={categoryData || []} columns={FrequentRequestColumn} hideCheckbox height={350} />
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <div className='px-6 py-2 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                    <div className='flex items-center justify-between py-3 mb-6' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                        <p className='px-1 py-1 text-[20px] mb-3' >Current Request Status</p>

                        <div className='flex items-center gap-8'>
                            <div className="relative">
                                <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                                    placeholder="Search here..." style={{
                                        border: '1px solid rgba(34, 96, 217, 1)',
                                        height: '50px',
                                        width: '300px'
                                    }} />
                                <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                                    <img src={SearchIcon} alt='SearchIcon' />
                                </div>
                            </div>
                            <div style={{
                                background: 'rgba(210, 228, 255, 1)', color: 'rgba(34, 96, 217, 1)', borderRadius: '3px',
                                width: '100px'
                            }}
                                className='px-3 py-3 text-center cursor-pointer'
                            >
                                View all
                            </div>
                        </div>

                    </div>
                    <DataTable rows={currentRequestData || []} columns={CurrentStatusColumn} hideCheckbox height={350} />

                </div>
            </div>

        </div>
    )
}
