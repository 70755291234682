import React from "react";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import DocumentIcon from "../../assets/file-black-icon.svg";
import DownloadIcon from "../../assets/download-icon.svg";
import UploadIcon from "../../assets/upload-icon.svg";

const BulkUpload = () => {
  return (
    <div className="px-8 pt-10 bg-[#F9F9F9]">
      <BreadCumbs
        firstCumbName="Dashboard"
        secoundCumbName="Import/Bulk Upload"
        pathToRedirect="allcategory"
      />
      <div className="bg-white custom-shadow rounded-[5px]  px-9 my-10 py-10 ">
        <p className="font-medium text-[#18283D] text-[22px]">
          Import/Bulk Upload
        </p>
        <div className="flex flex-col items-center my-16">
        <div className="flex flex-col items-center gap-5 border border-dashed border-[#9A9A9A] pt-28 pb-12 rounded-[10px] w-[600px] font-medium ">
        <img src={UploadIcon}/>
          <p className=" text-[#18283D] text-lg">
            Drag & Drop or{" "}<span className="text-[#2260D9] underline underline-offset-[3px] decoration-[1px]">Choose file</span>{" "}to upload
          </p>
          <p className="text-[#565656] text-sm">XLSX or CSV</p>
        </div>
        <button className="flex items-center justify-center bg-[#2260D9] text-white font-semibold text-base rounded-[3px] w-[180px] h-[50px] mt-10 mb-20">Upload</button>
        <div>
        <div className="flex flex-col gap-8">
        <p className="font-[#353F4F] font-semibold text-base">Template File to Download</p>
        <div className="bg-[#FCFCFC] flex items-center justify-between border border-[#A9A9A9] rounded-[10px] w-[600px] p-3">
        <div className="flex gap-6">
        <div className="p-0.5 border border-[#E1E1E1] rounded-[5px]">
        <div className="bg-[#E6FCEE] w-[46px] h-[49px] flex justify-center items-center rounded-[5px]">
          <img src={DocumentIcon} className=""/>
          </div>
        </div>
        <div className="flex flex-col justify-between py-0.5">
          <p className="text-[#353F4F] text-base">import_agent_template</p>
          <div className="flex items-center gap-2 text-[#B1B1B1] text-xs">
          <p>XLSX</p>
          <p className="font-semibold">⋅</p>
          <p>1.5 MB</p>
          </div>
        </div>
        </div>
        <div className="flex justify-between gap-3 py-2 px-3 border border-[#E1E1E1] rounded-[5px]">
          <img src={DownloadIcon} />
          <p className="text-[#353F4F] text-xs">Download</p>
        </div>
        </div>

        <div className="bg-[#FCFCFC] flex items-center justify-between border border-[#A9A9A9] rounded-[10px] w-[600px] p-3">
        <div className="flex gap-6">
        <div className="p-0.5 border border-[#E1E1E1] rounded-[5px]">
        <div className="bg-[#E6FCEE] w-[46px] h-[49px] flex justify-center items-center rounded-[5px]">
          <img src={DocumentIcon} className=""/>
          </div>
        </div>
        <div className="flex flex-col justify-between py-0.5">
          <p className="text-[#353F4F] text-base">import_agent_template</p>
          <div className="flex items-center gap-2 text-[#B1B1B1] text-xs">
          <p>XLSX</p>
          <p className="font-semibold">⋅</p>
          <p>1.5 MB</p>
          </div>
        </div>
        </div>
        <div className="flex justify-between gap-3 py-2 px-3 border border-[#E1E1E1] rounded-[5px]">
          <img src={DownloadIcon} />
          <p className="text-[#353F4F] text-xs">Download</p>
        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
