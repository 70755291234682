import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const getUserType = createAsyncThunk("getUserType", async () => {
  try {
    const response = await api.get("/user/user-details/");
    if (response.status === 200 && response.data.status === "success") {
      const result = response.data.result;

      console.log("userresult", result);
      return result;
    } else {
      throw new Error("Failed to fetch request count");
    }
  } catch (error) {
    console.error("Error fetching request count:", error);
    throw error;
  }
});

export const profileDetails = createAsyncThunk(
  "user/update",
  async ({ profileData }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/user/user-details/`, profileData);

      if (response.status === 200 && response.data.result) {
        return response.data.result;
      }

      return rejectWithValue("Failed to update user details");
    } catch (error) {
      console.error("Error updating user details:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
