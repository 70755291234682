import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const getRequestGraph = createAsyncThunk (
    "getRequestGraph",
    async () => {
        try{
            const response = await api.get("/agent/graph/");
            // console.log('response',response)
            if (response.status === 200 && response.data.status === "success"){
                const result = response.data.result;
                // console.log('result',result)
                return result;
            } else {
                throw new Error("Failed to fetch request count");
              }
            }
            catch (error) {
                console.error("Error fetching request count:", error);
                throw error; 
              }
            
        }
)