import { createSlice } from "@reduxjs/toolkit";
import { getAgentList } from "../../services/agentList";

const agentListSlice = createSlice({
  name: "agentData",
  initialState: {
    details:{},
    agentData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgentList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAgentList.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getAgentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default agentListSlice.reducer;
