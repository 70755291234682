import { createSlice } from "@reduxjs/toolkit";
import { getPartnertList } from "../../services/partnerList";

const partnerListSlice = createSlice({
  name: "partnerData",
  initialState: {
    details:{},
    partnerData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnertList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPartnertList.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getPartnertList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default partnerListSlice.reducer;
