import React, { useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { useDispatch, useSelector } from "react-redux";
import { getRequestGraph } from "../../services/requestGraph";
// List of months in correct order
const monthsInOrder = [
  "January", "February", "March", "April", "May", "June", 
  "July", "August", "September", "October", "November", "December"
];
function extractData(data) {
  if(!data) return {
    new: [],
    accepted: [],
    completed: []
  }
  

  // Initialize arrays for New, Accepted, and Completed
  const newArr = [];
  const acceptedArr = [];
  const completedArr = [];

  // Sort the data object by the correct month order
  monthsInOrder.forEach(month => {
      if (data[month]) {
          newArr.push(data[month].New);
          acceptedArr.push(data[month].Accepted);
          completedArr.push(data[month].Completed);
      } else {
          // If the month doesn't exist in the data, push default values
          newArr.push(0);
          acceptedArr.push(0);
          completedArr.push(0);
      }
  });

  // Return the three arrays
  return { new: newArr, accepted: acceptedArr, completed: completedArr };
}

const Chart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRequestGraph());
  }, [dispatch]);


  const graphData = useSelector((state) => state.graphInfo.details);
  // console.log('graphData',graphData)

  const xLabels = graphData?.X_labels || []; 
  const seriesData = graphData?.series || [];

  const result = extractData(graphData?.data);

  // console.log({ seriesData, graphData })
  return (
    <LineChart
    series={[
      { data: result.new, label: 'New' },
      { data: result.completed, label: 'Completed' },
      { data: result.accepted, label: 'Accepted' },
    ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}

      width={600}
      height={450}
    />
  );
};

export default Chart;

// import React, { useEffect } from "react";
// import { LineChart } from "@mui/x-charts/LineChart";
// import { useDispatch, useSelector } from "react-redux";
// import { getRequestGraph } from "../../services/requestGraph";

// const Chart = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getRequestGraph());
//   }, [dispatch]);

//   const graphData = useSelector((state) => state.graphInfo.details);
//   console.log('graphData', graphData);

//   const xLabels = graphData?.x_labels || []; 
//   const seriesData = graphData?.series || []; 

//   return (
//     <LineChart
//       series={seriesData.map((item, index) => ({
//         id: `Series ${index + 1}`,
//         curve: "linear",
//         data: item.data, 
//         lineWidth: 2,
//         markerSize: 0,
//       }))}
//       xAxis={[{ id: "x-axis", data: xLabels }]} 
//       yAxis={[{ id: "y-axis", data: graphData?.y_labels || [] }]} 
//       width={600}
//       height={450}
//     />
//   );
// };

// export default Chart;



