import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

// Create Request
export const createNewRequest = createAsyncThunk(
  "createNewRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const createRequest = await api.post(`/agent/`, requestData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create Request");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const getAllCurrentRequestList = createAsyncThunk(
  "getAllCurrentRequestList",
  async () => {
    const getAllCurrentRequestInfo = await api.get('/agent/');
    if (getAllCurrentRequestInfo.status === 200 && getAllCurrentRequestInfo.data) {
      return getAllCurrentRequestInfo.data
    }
    return getAllCurrentRequestInfo
  }
);

export const getAllPartnerRequestList = createAsyncThunk(
  "getAllPartnerRequestList",
  async (userId) => {
    const getAllPartnerRequestInfo = await api.get(`/agent/?partner_id=${userId}`);
    if (getAllPartnerRequestInfo.status === 200 && getAllPartnerRequestInfo.data) {
      return getAllPartnerRequestInfo.data
    }
    return getAllPartnerRequestInfo
  }
);

export const getRequestById = createAsyncThunk(
  "getRequestById",
  async ({status,id}) => {
    try {
      const response = await api.get(`/?status=${status}&partner_id=${id}`);

      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error("Failed to fetch data");
    } catch (error) {
      throw error.response?.data?.message || error.message; 
    }
  }
);


