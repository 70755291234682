import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import ProfileIcon from "../../assets/user-profile-icon.svg";
import ShieldIcon from "../../assets/security-shield-icon.svg";
import ProfileEditIcon from "../../assets/profile-edit-icon.svg";
import ProfileImageIcon from "../../assets/profile-image-icon.svg";
import ProfileImagePencilIcon from "../../assets/profile-image-pencil-icon.svg";
import PermissionIcon from "../../assets/permission-icon.svg";
import PermissionCheckIcon from "../../assets/permission-check-icon.svg";
import EyeOpen from "../../assets/eye-open-icon.svg";
import EyeClosed from "../../assets/eye-closed-icon.svg";
import { getUserType } from "../../services/userType";
import { profileDetails } from "../../services/userType";
import { useDispatch, useSelector } from "react-redux";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import EmployeesIcon from '../../assets/employeesicon.svg'
import AllEmployee from "../Partner-Employee/AllEmployee";

export default function Profile() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  const [isEditing, setIsEditing] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { data, status, userType } = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  const profile = useSelector((state) => state.userTypeData.details);
  console.log("state", profile);

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing && profile) {
      reset({
        profileName: profile.username,
        phone_number: profile.phone_number,
        mailId: profile.email,
        agentId: profile.agentId,
        address: profile.address,
        user_bio: profile.user_bio,
      });
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setProfileData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleSave = (data) =>{
  //   dispatch(profileDetails({profileData: data}));
  //   setIsEditing(false);
  // }

  const handleSave = (data) => {
    console.log("Edited data being saved:", data);
    dispatch(profileDetails({ profileData: data }))
      .then(() => {
        setIsEditing(false);
        dispatch(getUserType());
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  // const toggleEditMode = () => {
  //   setIsEditing(!isEditing);
  //   if (!isEditing) {
  //     reset(profile);
  //   }
  // };

  const inputClassName = isEditing
    ? "w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px] rounded-[3px]"
    : "";

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getFieldState,
    getValues,
    setError,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    console.log("Change password", data);
  };

  return (
    <div className="px-8 mt-10">
      <div className="mb-6">
        <BreadCumbs
          firstCumbName="Dashboard"
          secoundCumbName="View Profile"
          pathToRedirect=""
        />
      </div>

      <p className="py-5">Settings</p>

      <div className="border border-[#2260D9] rounded-[3px] mb-10">
        <div className=" grid grid-cols-7">
          <div className="col-span-2 left-side-bar border-r border-[#D9D9D9] pt-5">
            <div
              className={`cursor-pointer ${
                activeTab === "profile" ? "active" : ""
              }`}
              onClick={() => setActiveTab("profile")}
            >
              <div className="flex gap-3 py-4 px-5">
                <img src={ProfileIcon} alt="ProfileIcon" />
                <p>Profile</p>
              </div>
            </div>
            {userType === "partner" && (
                <div
                  className={`cursor-pointer ${
                    activeTab === "employees" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("employees")}
                >
                  <div className="flex gap-3 py-4 px-5">
                    <img src={EmployeesIcon} alt="EmployeesIcon" />
                    <p>Employees</p>
                  </div>
                </div>
              )}

            <div
              className={`cursor-pointer ${
                activeTab === "security" ? "active" : ""
              }`}
              onClick={() => setActiveTab("security")}
            >
              <div className="flex gap-3 py-4 px-5">
                <img src={ShieldIcon} alt="ShieldIcon" />
                <p>Security</p>
              </div>
            </div>
            <div
              className={`cursor-pointer ${
                activeTab === "permission" ? "active" : ""
              }`}
              onClick={() => setActiveTab("permission")}
            >
              <div className="flex gap-3 py-4 px-5">
                <img src={PermissionIcon} alt="LockIcon" />
                <p>Permission</p>
              </div>
            </div>
          </div>
          <div className="col-span-5 px-9 py-9 ">
            {activeTab === "profile" && (
              <>
                <div className="flex justify-between items-start ">
                  <p className="font-medium">Profile Picture</p>
                  {isEditing ? (
                    <button
                      onClick={handleSubmit(handleSave)}
                      className="bg-[#2260D9] text-white font-medium w-[150px] py-3 border text-sm rounded-[3px]"
                    >
                      <p>Save Changes</p>
                    </button>
                  ) : (
                    <button
                      className="flex gap-2 items-center text-[14px] w-[100px] py-3 justify-center border border-[#353F4F] rounded-[3px]"
                      onClick={toggleEditMode}
                    >
                      <img src={ProfileEditIcon} alt="ProfileEditIcon" />
                      Edit
                    </button>
                  )}
                </div>

                <div className="py-4 relative">
                  <img src={ProfileImageIcon} alt="ProfileImageIcon" />
                  <img
                    src={ProfileImagePencilIcon}
                    className="absolute top-[50%] left-2 cursor-pointer"
                    alt="ProfileImagePencilIcon"
                  />
                </div>

                <div className="grid grid-cols-3 text-[13px] py-6 gap-6">
                  <div className="pb-4">
                    <p className="text-[#747474]">Profile Name</p>
                    {isEditing ? (
                      <input
                        type="text"
                        {...register("profileName", {
                          required: "This field is required",
                        })}
                        className={`mt-1.5 ${inputClassName}`}
                        disabled={!isEditing}
                      />
                    ) : (
                      <p className="pt-2.5">{profile.username}</p>
                    )}
                  </div>
                  <div className="pb-4">
                    <p className="text-[#747474]">Contact Number</p>
                    {isEditing ? (
                      <input
                        type="text"
                        {...register("phone_number", {
                          required: "This field is required",
                        })}
                        className={`mt-1.5 ${inputClassName}`}
                        disabled={!isEditing}
                      />
                    ) : (
                      <p className="pt-2.5">{profile.phone_number}</p>
                    )}
                  </div>
                  <div className="pb-4">
                    <p className="text-[#747474]">Mail ID</p>
                    {isEditing ? (
                      <input
                        type="email"
                        {...register("mailId", {
                          required: "This field is required",
                        })}
                        className={`mt-1.5 ${inputClassName}`}
                        disabled={!isEditing}
                      />
                    ) : (
                      <p className="pt-2.5">{profile.email}</p>
                    )}
                  </div>

                  <div>
                    <p className="text-[#747474]">Agent ID</p>
                    {isEditing ? (
                      <input
                        type="text"
                        {...register("agentId", {
                          required: "This field is required",
                        })}
                        className={`mt-1.5 ${inputClassName}`}
                        disabled={!isEditing}
                      />
                    ) : (
                      <p className="pt-2.5">{profile.agentId}</p>
                    )}
                  </div>
                  <div className={`${isEditing ? "col-span-2" : ""}`}>
                    <p className="text-[#747474]">Address</p>
                    {isEditing ? (
                      <textarea
                        {...register("address", {
                          required: "This field is required",
                        })}
                        className={`mt-1.5 ${inputClassName}`}
                        disabled={!isEditing}
                      />
                    ) : (
                      <p className="pt-2.5">{profile.address}</p>
                    )}
                  </div>
                  <div></div>
                </div>
                <div className="text-[13px] pb-4">
                  <p className="text-[#747474]">Professional Bio</p>
                  {isEditing ? (
                    <textarea
                      {...register("user_bio", {
                        required: "This field is required",
                      })}
                      className={`mt-1.5 ${inputClassName}`}
                      disabled={!isEditing}
                    />
                  ) : (
                    <p className="pt-2.5">{profile.user_bio}</p>
                  )}
                </div>
              </>
            )}

            {userType === "partner" && activeTab === "employees" && (
              <>
                <AllEmployee/>
              </>
            )}

            {activeTab === "security" && (
              <>
                <div className="flex justify-between items-center ">
                  <p className="text-[#2260D9] font-medium">Password</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="py-7">
                    <div className="relative mb-6">
                      <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                        Current Password
                      </label>

                      <div className="relative">
                        <input
                          {...register("current-password", {
                            required: "This field is required",
                          })}
                          type={showCurrentPassword ? "text" : "password"}
                          // className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none
                          //                                   focus-visible:outline-none text-[14px] h-[60px]"
                          className={`${inputClassName}`}
                          placeholder={"Current Password"}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors["current-password"]}
                        />
                        <img
                          className="absolute top-4 right-4 w-[25px]"
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                          src={showCurrentPassword ? EyeOpen : EyeClosed}
                          alt="EyeCloseIcon"
                        />
                        <div className="text-[#2260D9] font-medium text-xs pt-2">
                          Last Update 21/08/2024
                        </div>
                        {errors["current-password"] && (
                          <p className="error" role="alert">
                            {errors["current-password"].message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="relative mb-6">
                      <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                        New Password
                      </label>

                      <div className="relative">
                        <input
                          {...register("new-password", {
                            required: "This field is required",
                          })}
                          type={showNewPassword ? "text" : "password"}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={"New Password"}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors["new-password"]}
                        />
                        <img
                          className="absolute top-4 right-4 w-[25px]"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          src={showNewPassword ? EyeOpen : EyeClosed}
                          alt="EyeCloseIcon"
                        />
                        {errors["new-password"] && (
                          <p className="error" role="alert">
                            {errors["new-password"].message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="relative mb-6">
                      <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                        Confirm Password
                      </label>

                      <div className="relative">
                        <input
                          {...register("confirm-password", {
                            required: "This field is required",
                          })}
                          type={showConfirmPassword ? "text" : "password"}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={"Confirm Password"}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors["confirm-password"]}
                        />
                        <img
                          className="absolute top-4 right-4 w-[25px]"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          src={showConfirmPassword ? EyeOpen : EyeClosed}
                          alt="EyeCloseIcon"
                        />
                        {errors["confirm-password"] && (
                          <p className="error" role="alert">
                            {errors["confirm-password"].message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="relative mb-6 flex justify-end mt-10">
                      <button className="bg-primary-blue text-white py-4 px-4 w-[200px] rounded-[3px]">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}

            {activeTab === "permission" && (
              <div>
                <table className="permission-table text-[14px]">
                  <thead>
                    <tr>
                      <th>Name of Permissions</th>
                      <th>Create</th>
                      <th>View</th>
                      <th>Edit</th>
                      <th>Accept</th>
                      <th>Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Request</td>
                      <td>
                        <img
                          src={PermissionCheckIcon}
                          alt="PermissionCheckIcon"
                        />
                      </td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Partnet</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Category</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Profile</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Password</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
