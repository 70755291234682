import React from "react";
import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import { useNavigate } from "react-router";

const BreadCumbs = ({
  pathToRedirect = "",
  firstCumbName = "Dashboard",
  secoundCumbName = "Agent Status",
  pathToRedirectSecond = "",
  thirdCumbName = "",
}) => {
  const navigate = useNavigate();

  const onClickofFirstCumb = () =>
    pathToRedirect ? navigate(`/${pathToRedirect}`) : navigate(`/`);

  const onClickofSecondCumb = () =>
    pathToRedirectSecond
      ? navigate(`/${pathToRedirectSecond}`)
      : navigate(`/`);

  return (
    <div className="mb-6">
      <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li class="inline-flex items-center">
            <span
              class="inline-flex items-center text-sm text-[#353F4F] hover:text-blue-600 dark:text-gray-400 cursor-pointer"
              onClick={onClickofFirstCumb}
            >
              {firstCumbName}
            </span>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <img src={BreadCrumIcon} alt="BreadCrumIcon" />
              <span
                onClick={onClickofSecondCumb}
                className={`ms-1 text-sm md:ms-2 cursor-pointer ${
                  thirdCumbName
                    ? "text-[#353F4F]" 
                    : "text-primary-blue" 
                }`}
              >
                {secoundCumbName}
              </span>
            </div>
          </li>
          {thirdCumbName && (
            <li aria-current="page">
              <div class="flex items-center">
                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                <span class="ms-1 text-sm md:ms-2 text-primary-blue">
                  {thirdCumbName}
                </span>
              </div>
            </li>
          )}
        </ol>
      </nav>
    </div>
  );
};

export default BreadCumbs;
