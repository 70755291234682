import { createSlice } from "@reduxjs/toolkit";
import { createNewPartnerRequest, getPartnerList } from "../../services/partner";
import { getAllPartnerList } from "../../services/partner";
import { getTotalEmployeesColumnList } from "../../services/partner";
import { getParterDetailsbyId } from "../../services/partner";

const initialState = {
  partnerdata: [],
  details: {},
  partnerList: [],
  loading: false,
  status: "",
  error: "",
};

export const partnerSlice = createSlice({
  name: "partnetInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewPartnerRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewPartnerRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.status = "Partner Created Successfully";
      })
      .addCase(createNewPartnerRequest.rejected, (state, action) => {
        state.loading = false;
        state.error =
          typeof action.payload === "object"
            ? action.payload
            : { message: action.payload || "Something went wrong!" };
      });
    builder
      .addCase(getAllPartnerList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllPartnerList.fulfilled, (state, action) => {
        return {
          ...state,
          partnerData: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getAllPartnerList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    
    builder
      .addCase(getPartnerList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPartnerList.fulfilled, (state, action) => {
        return {
          ...state,
          partnerList: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getPartnerList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getTotalEmployeesColumnList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTotalEmployeesColumnList.fulfilled, (state, action) => {
        state.details = Array.isArray(action.payload) ? action.payload : [];
        state.loading = false;
      })
      .addCase(getTotalEmployeesColumnList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

      builder
      .addCase(getParterDetailsbyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParterDetailsbyId.fulfilled, (state, action) => {
        state.details = action.payload;
        state.loading = false;
      })
      .addCase(getParterDetailsbyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

      
  },
});

export default partnerSlice.reducer;
