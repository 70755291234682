import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setNewPassword } from "../../services/login";


const ChangePassword = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token  = useSelector((state) => state.loginInfo.data.result.token);
  console.log('token',token)

  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

 
  const handlePasswordChange = () => {
    const { newPassword, confirmPassword } = passwords;

    if (newPassword === confirmPassword) {
      if (token) {
        dispatch(setNewPassword({ new_password: newPassword, confirm_password: confirmPassword, token }));
        navigate("/login")
      } else {
        alert("Token is missing. Please login again.");
      }
    } else {
      alert("Passwords don't match!");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col max-w-[640px] border border-[#2260D9] px-7 rounded-[5px] h-[635px]">
        <div className="flex flex-col items-center gap-5 py-10">
          <p className="font-bold text-[38px] text-primary-blue">CRRMS</p>
          <p className="font-medium text-[#353F4F] text-2xl px-28 text-center">
            Community Request and Referrals Management System
          </p>
        </div>
        {!passwordChanged ? (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <label htmlFor="newPassword" className="text-[#353F4F] text-sm ">
                Enter New Password
              </label>
              <input
                type="password"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handleChange}
                placeholder="Enter your New Password"
                className="text-[#353F4F] border-[1px] border-[#2260D9] text-sm focus:outline-none rounded-[3px] placeholder:text-[#D1D1D1] placeholder:text-sm py-4 px-[18px] w-full"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label
                htmlFor="confirmPassword"
                className="text-[#353F4F] text-sm "
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm your New Password"
                className="text-[#353F4F] border-[1px] border-[#2260D9] text-sm focus:outline-none rounded-[3px] placeholder:text-[#D1D1D1] placeholder:text-sm py-4 px-[18px] w-full"
              />
            </div>

            <button
              onClick={handlePasswordChange}
              className="bg-[#2260D9] text-white py-3 rounded-[3px] mt-10"
            >
              Change Password
            </button>
          </div>
        ) : (
          <p className="text-[#353F4F] text-center font-medium text-[22px] pt-40">
            Password Reset Successful
          </p>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
