import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "../../assets/input-search-icon.svg";
import EmployeeImage from "../../assets/employeeimage.svg";
import EmployeeDelete from "../../assets/employedelete.svg";
import EmployeeEdit from "../../assets/employeeedit.svg";
import { getEmployeesDetails } from "../../services/employees";
import { useDispatch, useSelector } from "react-redux";

export default function AllEmployee() {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userInfo.data);

  const id = userData.id;

  const state = useSelector((state) => state);
  console.log("state", state);
  
  useEffect(() => {
    if (id) {
      dispatch(getEmployeesDetails(id));
    }
  }, [dispatch, id]);

  return (
    <>
      <div className="flex items-center justify-between border-b border-[#D9D9D9] pb-3 ">
        <h5 className="text-[#353F4F] font-medium text-[20px]">Employees</h5>
        <div className="flex items-center gap-4">
          <div className="relative">
            <input
              type="text"
              id="search-navbar"
              className="block w-full p-2 text-sm text-gray-900 border-none focus:outline-none"
              placeholder="Search here..."
              style={{
                border: "1px solid rgba(34, 96, 217, 1)",
                height: "50px",
                width: "300px",
              }}
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
            {/* <div className="text-[13px]">{`${discussion.name}`}</div> */}
          </div>
          <button
            className="bg-[#2260D9] text-white rounded-[2px] p-3 focus:outline-none"
            onClick={() => {}}
          >
            <AddIcon className="" />
            Add Employee
          </button>
        </div>
      </div>
      <div className="border-b border-[#D9D9D9] px-3 py-8 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img src={EmployeeImage} alt="" />
          <div className="flex flex-col gap-2 justify-start text-[#18283D] text-[14px] font-normal">
            <p className="text-[#18283D] text-[14px] font-normal">
              John Doe <span>#Emp009</span>
            </p>
            <p className="text-[#18283D] text-[14px] font-normal">
              +12 9876543210 , <span>jhonsmith@abcd.com</span>
            </p>
            <p className="text-[#18283D] text-[14px] font-normal">
              San Francisco , <span>USA</span>
            </p>
          </div>
        </div>
        <div className="flex gap-8 items-center">
          <img src={EmployeeDelete} alt="EmployeeDelete" />
          <img src={EmployeeEdit} alt="EmployeeEdit" />
        </div>
      </div>
    </>
  );
}
