import { createSlice } from "@reduxjs/toolkit";
import { categoryApiResponse } from "../../constants/apiResponse";
import {
  getAllCategory,
  getAllCategoryList,
  getCategoryById,
  updateCategory,
  CreateCategoryVisibility,
  getCategoryVisibilityLength,
  getAddNewCategory
} from "../../services/category";

const initialState = {
  data: [],
  details: {
  },
  loading: false,
  status: "",
  error: "",
};

export const categorySlice = createSlice({
  name: "categoryInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategory.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        console.log(action, "action");
        return {
          ...state,
          data: action.payload,
          details: {},
          status: categoryApiResponse.load,
          loading: false,
        };
      })
      .addCase(getAllCategory.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(getAllCategoryList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCategoryList.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          details: {},
          status: categoryApiResponse.load,
          loading: false,
        };
      })
      .addCase(getAllCategoryList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      })
      

    builder
      .addCase(getCategoryById.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getCategoryById.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      })

      builder
      .addCase(updateCategory.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(updateCategory.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      })

      builder
      .addCase(CreateCategoryVisibility.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(CreateCategoryVisibility.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(CreateCategoryVisibility.rejected, (state, action) => {
        console.log("Error fetching visibility:", action.payload || action.error);
        return {
          ...state,
          loading: false,
          error: action.payload || action.error.message,
        };
      });

      builder
      .addCase(getCategoryVisibilityLength.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getCategoryVisibilityLength.fulfilled, (state, action) => {
        console.log(action.payload, 'payload')
        return {
          ...state,
          data: action.payload.unassigned,
          status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getCategoryVisibilityLength.rejected, (state, action) => {
        console.log("Error fetching visibility:", action.payload || action.error);
        return {
          ...state,
          loading: false,
          error: action.payload || action.error.message,
        };
      })

      .addCase(getAddNewCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAddNewCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.status = "Agent Created Successfully";
      })
      .addCase(getAddNewCategory.rejected, (state, action) => {
        state.loading = false;
        state.error =
          typeof action.payload === "object"
            ? action.payload
            : { message: action.payload || "Something went wrong!" };
      });
      
  },
});

export default categorySlice.reducer;
