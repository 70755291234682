import React, { useEffect, useState } from "react";
import DataTable from "../../shared/DataGrid";
import { TopAgentColumn } from "../../utills/tableFields";
import { TopAgentRows } from "./adminRows";
import MoreIcon from "../../assets/more-icon.svg";
import { getAgentList } from "../../services/agentList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";



const TopAgentList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
const navigate = useNavigate();
const dispatch = useDispatch();

const agentData = useSelector((state) => state.agentData.details);

useEffect(() => {
  dispatch(getAgentList());
}, [dispatch]);



const handleClick = (event, data) => {
  setAnchorEl(event.currentTarget);
};

  const AgentColumn = [
    {
      field: "name",
      headerName: "Agent Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-4  w-full">
            <img src={params.row.image} className="w-8 h-8 rounded-full" />
            <p>{params.value}</p>
          </div>
        );
      },
    },

    ...TopAgentColumn,

    {
      field: "action",
      headerName: "Action", 
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          // <div className="cursor-pointer flex items-center justify-center h-full">
          //   <img src={MoreIcon} alt="More Icon" />
          // </div>
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/view-agent/${params.id}`);
                  }}
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const rows =
    agentData && agentData.length > 0
      ? agentData.map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          request: item.request_count,
        }))
      : [];

  return (
    <div className="pt-6">
      <DataTable
        rows={rows}
        columns={AgentColumn}
        hideCheckbox
        hideFooter
        height={450}
      />
    </div>
  );
};

export default TopAgentList;
