import {
    createAsyncThunk,
} from "@reduxjs/toolkit";

import api from "./api";

export const getEmployeesDetails = createAsyncThunk(
    "getEmployeeDetails",
    async (id) => {
        const getAllEmployeeInfo = await api.get(`/agent/employee/?partner_id=${id}`);
        if (getAllEmployeeInfo.status === 200 && getAllEmployeeInfo.data) {
            const result =  getAllEmployeeInfo.data.result
            return result;
        }
        return getAllEmployeeInfo
    }
)