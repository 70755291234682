import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginData } from "../../services/login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EyeOpen from "../../assets/eye-open-icon.svg";
import EyeClosed from "../../assets/eye-closed-icon.svg";
import BlueTickicon from "../../assets/images/bluetick-icon.jpg"

const LogIn = () => {

  const [showSuccessDiv, setShowSuccessDiv] = useState(false);

  const dispatch = useDispatch();

  const loginInfo = useSelector((state) => state.loginInfo.data);
  console.log("loginInfo", loginInfo);

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    console.log("Login Payload:", login);
    try {
      await dispatch(loginData(login));
      localStorage.setItem("login", true);
      setShowSuccessDiv(true); 
      setTimeout(() => {
        navigate("/"); 
      }, 2000); 
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col max-w-[640px] border border-[#2260D9] px-7 rounded-[5px] h-[635px]">
        <div className="flex flex-col items-center gap-5 py-10">
          <p className="font-bold text-[38px] text-primary-blue">CRRMS</p>
          <p className="font-medium text-[#353F4F] text-2xl px-28 text-center">
            Community Request and Referrals Management System
          </p>
        </div>{!showSuccessDiv ? ( <div className="flex flex-col gap-6 pb-28">
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-[#353F4F] text-sm ">
              Enter your Official E-mail ID
            </label>
            <input
              type="email"
              name="email"
              value={login.email}
              onKeyDown={handleKeyPress} 
              onChange={handleChange}
              placeholder="Enter your Official E-mail ID"
              className="text-[#353F4F] border-[1px] border-[#2260D9] text-sm focus:outline-none  rounded-[3px] placeholder:text-[#D1D1D1] placeholder:text-sm  py-4 px-[18px] w-full"
            />
          </div>

          <div className="flex flex-col gap-1 relative">
            <label htmlFor="password" className="text-[#353F4F] text-sm ">
              Password
            </label>
            <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={login.password}
              onKeyDown={handleKeyPress} 
              onChange={handleChange}
              placeholder="Password"
              className="text-[#353F4F] border-[1px] border-[#2260D9] text-sm focus:outline-none rounded-[3px] placeholder:text-[#D1D1D1] placeholder:text-sm  py-4 px-[18px] w-full"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)} 
              className="absolute right-3 top-[50%] transform -translate-y-[50%] text-[#2260D9] focus:outline-none"
            >
              <img src={showPassword ? EyeOpen : EyeClosed} />
            </button>
          </div>
          </div>

          <div className="flex justify-between">
            <div className="flex items-center gap-3">
              <input
                type="checkbox"
                style={{ border: "1px solid rgba(210, 226, 255, 1)" }}
                className="w-4 h-4 rounded-[3px] border border-[#D2E2FF]"
              />
              <label htmlFor="subscribe" className="text-[#353F4F]">
                Remember Me
              </label>
            </div>
            <p className="text-[#2260D9] underline decoration-1 underline-offset-2">
              <Link to="/forgotpassword">Forgot Password ?</Link>
            </p>
          </div>
          <button
            onClick={handleLogin}
            className="bg-[#2260D9] text-white py-3 rounded-[3px]"
          >
            Login
          </button>
        </div>):( 
          <div className="flex justify-center gap-4 mt-5 p-4 pt-20 ">
        
          <p className="text-[#353F4F] font-medium text-[22px]">Successfully Logged In</p>
          </div>)}
       
        {/* {showSuccessDiv && (
         
        )} */}
      </div>
    </div>
  );
};

export default LogIn;
