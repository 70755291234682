function Switch({ value, onChange }) {
  return (
    <div
    onClick={() => onChange(!value)}
    className={`border-2 font-medium text-xl rounded-3xl px-1.5 py-1 flex items-center justify-between w-20 cursor-pointer ${
      value ? "text-[#0A66C2] border-[#0A66C2]" : "text-[#DC3545] border-[#DC3545]"
    }`}
  >
      {value && "On"}
      <span className={`w-6 h-6 rounded-full ${value ? "bg-[#0A66C2]" : "bg-[#DC3545]"}`}></span>
      {!value && "Off"}
    </div>
  );
}

export default Switch;
