import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import RequestDetails from "./RequestDetails";

import DataTable from "../../../shared/DataGrid";
import { AllRequestTab } from "../../../utills/formFields";
import { RequestColumn } from "../../../utills/tableFields";

import BreadCrumIcon from "../../../assets/breadcrumb-right-icon.svg";
import SearchIcon from "../../../assets/input-search-icon.svg";
import MoreIcon from "../../../assets/more-icon.svg";
import FileIcon from "../../../assets/file-icon.svg";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AgentRequest from "./AgentRequest";
import AdminRequest from "./AdminRequest";

export default function AllRequest() {
  const navigate = useNavigate();

  const [actionTab, setActiveTab] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [viewMode, setViewMode] = useState("detail-view");
  const [searchParams] = useSearchParams();

  const { userType } = useSelector((state) => state.userInfo);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  useEffect(() => {
    if (searchParams.has("view")) {
      const filterType = searchParams.get("view");
      if (filterType !== "") {
        setViewMode(filterType);
      }
    } else {
      setViewMode("detail-view");
    }
  }, [searchParams]);

  const MyRequestColumns = [
    ...RequestColumn,
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      id: 5,
      renderCell: (params) => {
        return (
          <>
            <div className="relative flex items-center h-full cursor-pointer"></div>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <div
              className="flex items-center h-full cursor-pointer"
              onClick={(e) => handleClick(e, params.row)}
            >
              <img src={MoreIcon} alt="MoreIcon" />
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div className="px-8 mt-10">
      <div className="mb-6">
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            {userType === "consumer" && (
              <>
                <li className="inline-flex items-center">
                  <span
                    className="inline-flex items-center text-sm text-gray-700 cursor-pointer hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    onClick={() => navigate("/")}
                  >
                    Dashboard
                  </span>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                    <span className="text-sm ms-1 md:ms-2 text-primary-blue">
                      All Request
                    </span>
                  </div>
                </li>
              </>
            )}

            {userType === "other" && (
              <>
                <li className="inline-flex items-center">
                  <span
                    className="inline-flex items-center text-sm text-gray-700 cursor-pointer hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    onClick={() => undefined}
                  >
                    Request
                  </span>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                    <span className="text-sm ms-1 md:ms-2 text-primary-blue">
                      Request status
                    </span>
                  </div>
                </li>
              </>
            )}
          </ol>
        </nav>
      </div>
      {userType === "consumer" && (
        <>
          {viewMode === "list-view" && (
            <>
              <div className="flex justify-between pb-4">
                <div>Request</div>
                <div className="flex gap-2">
                  <img src={FileIcon} alt="FileIcon" />
                  <p
                    className="cursor-pointer text-primary-blue"
                    onClick={() => navigate("/all-request?view=detail-view")}
                  >
                    Detail View
                  </p>
                </div>
              </div>

              <div
                className="px-3 py-5 mb-5"
                style={{
                  boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
                  borderRadius: "5px",
                }}
              >
                <div className="details-info">
                  {
                    <div
                      className="flex items-center justify-between px-5 mb-8 "
                      style={{
                        borderBottom: "1px solid rgba(211, 211, 211, 1)",
                      }}
                    >
                      <ul className="tab-list">
                        {AllRequestTab.map((discussion, index) => (
                          <li
                            className={`${
                              actionTab === discussion.key ? "active" : ""
                            } relative`}
                            key={index}
                            onClick={() => setActiveTab(discussion.key)}
                          >
                            <div className="flex justify-center pb-1">
                              <div
                                className={`total-proram-count relative ${
                                  actionTab === discussion.key ? "active" : ""
                                }`}
                              >
                                10
                              </div>
                            </div>
                            <div className="text-[13px]">
                              {" "}
                              {`${discussion.name}`}
                            </div>
                            {actionTab === discussion.key && <span></span>}
                          </li>
                        ))}
                      </ul>

                      <div className="relative">
                        <input
                          type="text"
                          id="search-navbar"
                          className="block w-full p-2 text-sm text-gray-900 border-none"
                          placeholder="Search here..."
                          style={{
                            border: "1px solid rgba(34, 96, 217, 1)",
                            height: "50px",
                            width: "390px",
                          }}
                        />
                        <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                          <img src={SearchIcon} alt="SearchIcon" />
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <DataTable rows={[]} columns={MyRequestColumns} hideCheckbox />
              </div>
            </>
          )}

          {viewMode === "detail-view" && (
            <div
              className="px-3 py-5 mb-5 request-details"
              style={{
                boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
                borderRadius: "5px",
              }}
            >
              <RequestDetails handleView={() => setViewMode("list-view")} />
            </div>
          )}
        </>
      )}
      {userType === "other"  && <AgentRequest />}
      {(userType === "admin" || userType === "agent") && <AdminRequest />}
    </div>
  );
}
