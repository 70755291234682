import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { verifyResetCode } from "../../services/login";

const ConfirmOtp = () => {
  const { email } = useParams();
  const [otp, setOtp] = React.useState(Array(6).fill(""));
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  useEffect(() => {
    const isComplete = otp.every((value) => value.length === 1);
    setIsOtpComplete(isComplete);
  }, [otp]);

  const handleChange = (e, index) => {
    const newOtp = otp.slice();
    newOtp[index] = e.target.value.slice(0, 1);
    setOtp(newOtp);

    if (e.target.value.length === 1 && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  

  const handleConfirmOtp = async () => {
    if (isOtpComplete) {
      const reset_code = otp.join("");  
      console.log("Submitting OTP:", reset_code, "Email:", email);
  
      try {
        const result = await dispatch(verifyResetCode({ reset_code, email })).unwrap();  
        console.log("OTP verification success:", result); 
        const token = result.token; 
        navigate("/changepassword");
      } catch (error) {
        console.error("OTP verification failed:", error);

      }
    }
  };
  return (
    <div>
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col max-w-[640px] border border-[#2260D9] px-7 gap-10 rounded-[5px] h-[635px]">
          <div className="flex flex-col items-center gap-5 py-10">
            <p className="font-bold text-[38px] text-primary-blue">CRRMS</p>
            <p className="font-medium text-[#353F4F] text-2xl px-28 text-center">
              Community Request and Referrals Management System
            </p>
          </div>

          <div className="flex flex-col gap-5">
            <div className="flex justify-between">
              <p className="text-base">
                Enter the Code sent to
                <span className="font-semibold pl-2">Abcd@crrms.com</span>
              </p>
              <p className="text-[#2260D9] font-semibold underline decoration-1 underline-offset-2">
                Change Mail-ID
              </p>
            </div>

            <div className="flex justify-between w-full h-full">
              {otp.map((value, index) => (
                <Grid item key={index}>
                  <TextField
                    id={`otp-input-${index}`}
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center",
                        width: "40px",
                        fontSize: "20px",
                        padding: "10px",
                        margin: "0 5px",
                      },
                    }}
                    variant="outlined"
                    type="text"
                    impouMode="numeric"
                    sx={{
                      width: "65px",
                      "& .MuiOutlinedInput-root": {
                        height: "65px",
                      },
                    }}
                  />
                </Grid>
              ))}
            </div>

            <p className="text-xs">
              Didn't receive any Mail?
              <span className="text-[#2260D9] font-semibold underline decoration-1 underline-offset-2 pl-1.5">
                Resend
              </span>
            </p>
          </div>
          <div className="flex flex-col pb-28">
            <button
              className={`py-3 rounded-[3px] ${
                isOtpComplete
                  ? "bg-[#2260D9] text-white"
                  : "bg-[#E4E4E4] text-[#BBBBBB]"
              }`}
              disabled={!isOtpComplete}
              onClick={handleConfirmOtp}
            >
              Cofirm OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOtp;
