import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";

import Tooltip from "../../../shared/Tooltip";
import {
  PrimaryRequestInfoFields,
  NewRequestDetailsFields,
  SeconaryRequestInfoFields,
} from "../../../utills/formFields";

import CloseIcon from "../../../assets/close-red-icon.svg";
import LocationIcon from "../../../assets/location-icon.svg";
import UploadIcon from "../../../assets/upload-box-icon.svg";
import BreadCrumIcon from "../../../assets/breadcrumb-right-icon.svg";
import { Button } from "../../../shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../../services/category";
import { createNewRequest } from "../../../services/request";

export default function CreateRequest() {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getFieldState,
    getValues,
    setError,
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const categoryList = useSelector((state) => {
    return state.categoryInfo?.data;
  });
  console.log(categoryList, "categoryList");

  const onSubmit = (data) => {
    console.log("Request", data);

    const formattedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      contact: data.contact,
      alternative_number: data.alternative_number,
      address: data.address,
      id_proof: data.id_proof,
      communication_preference: data.communication_preference,
      request_via: data.request_via,
      request_type: data.request_type,
      category: categoryList?.find((cat) => cat.name === data.category)?.id,
      beneficiaries: data.beneficiaries,
      expected_duration: data.expected_duration,
      urgency_level: data.urgency_level,
      request_status_desc: data.request_status_desc || "description",
      request_status: data.request_status,
      description: data.description,
      attachment: data.attachment,
      contact_firstname: data.contact_firstname,
      contact_lastname: data.contact_lastname,
      contact_number: data.contact_number,
      contact_address: data.contact_address,
      partners: data.partners,
      refer_loop: data.refer_loop,
    };

    dispatch(createNewRequest(formattedData))
      .then(() => {
        navigate("/all-request");
      })
      .catch((err) => console.error("Error creating agent:", err));
  };

  useEffect(() => {
    reset({ urgency_level: "low" });
    dispatch(getAllCategory());
  }, []);

  return (
    <div className="dashboard-content px-8 mt-10">
      <div className="mb-6">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li class="inline-flex items-center">
              <span
                class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                onClick={() => navigate("/")}
              >
                Dashboard
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">
                  New Request
                </span>
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <div className="title flex justify-between py-3 px-8 items-center">
        <div className="flex gap-4">
          <h3 className="text-[20px]">New Request</h3>
        </div>
      </div>

      <div
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
          marginBottom: "20px",
          borderRadius: "5px",
          border: "1px solid rgba(34, 96, 217, 1)",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-8 py-4">
            <div
              className="text-primary-blue text-[18px] py-5 mb-8"
              style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
            >
              Client Primary Information
            </div>
            <div className="flex flex-wrap gap-4">
              {PrimaryRequestInfoFields.map((field, index) => {
                return (
                  <div className={`relative mb-6  ${field.width}`} key={index}>
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-normal mb-2"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    {field.type === "input" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          type={field.fieldType}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors[field.name]}
                        />
                        {field.icon && field.icon === "location" && (
                          <img
                            className="absolute top-4 right-4"
                            src={LocationIcon}
                            alt="LocationIcon"
                          />
                        )}

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div
              className="text-primary-blue text-[18px] py-5 mb-8"
              style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
            >
              Request Details
            </div>

            <div className="flex flex-wrap gap-4">
              {NewRequestDetailsFields.map((field, index) => {
                return (
                  <div className={`relative mb-6  ${field.width}`} key={index}>
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-normal mb-2"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    {field.type === "input" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          type={field.fieldType}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors[field.name]}
                        />
                        {field.icon && field.icon === "location" && (
                          <img
                            className="absolute top-4 right-4"
                            src={LocationIcon}
                            alt="LocationIcon"
                          />
                        )}

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : field.type === "dropdown" ? (
                      <>
                        <select
                          {...register(field.name, field.inputRules)}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg 
                                                                focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                            borderRight: "16px solid transparent",
                          }}
                        >
                          <option value="">Select</option>
                          {(field.name === "category"
                            ? categoryList
                            : field.options
                          ).map((option, index) => {
                            return (
                              <option value={option.value} key={index}>
                                {option.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </>
                    ) : field.type === "radio" ? (
                      <>
                        <div className="flex items-center me-4">
                          {field.options.map((option, index) => (
                            <div
                              value={getValues[field.name]}
                              option={option.value}
                              className={`flex items-center me-4 ${
                                getValues[field.name] === option.value
                                  ? "bg-primary-blue"
                                  : "bg-light-blue"
                              } py-3 px-5`}
                              key={index}
                            >
                              <input
                                type="radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100
                                                                                border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                                                                                dark:border-gray-600"
                                {...register(field.name, field.inputRules)}
                                value={option.value}
                                // checked={getValues[field.name] === option.value}
                              />
                              <label className="ms-2 text-[12px] text-gray-900 dark:text-gray-300">
                                {option.name}
                              </label>
                            </div>
                          ))}
                        </div>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </>
                    ) : field.type === "textbox" ? (
                      <>
                        <textarea
                          id="message"
                          rows="4"
                          className={`block p-2.5 input-bg w-full text-sm text-gray-900  
                                                                    focus-visible:outline-none focus-visible:border-none`}
                          style={{ borderRadius: "3px" }}
                          placeholder={field.placeholder}
                          {...register(field.name, field.inputRules)}
                        ></textarea>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </>
                    ) : field.type === "file" ? (
                      <>
                        <div className="flex items-center justify-center w-full">
                          <label
                            htmlFor={field.name}
                            className="flex flex-col items-center justify-center w-full h-64 cursor-pointer
                                                                                bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100
                                                                                 dark:hover:bg-gray-600"
                            style={{
                              border: "2px dashed rgba(34, 96, 217, 1)",
                            }}
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <img src={UploadIcon} alt="Upload Image" />
                              <p className="mb-2 mt-2 text-sm text-light-gray">
                                <span>Add / Browse</span>
                              </p>
                            </div>
                            <input
                              id={field.name}
                              type="file"
                              {...register(field.name, field.inputRules)}
                              // onChange={(e) => {
                              //     imageField.onChange(e);
                              //     if (e.target.files && e.target.files[0]) {
                              //         let types = ['image/png', 'image/jpeg']
                              //         if (types.includes(e.target.files[0].type)) {
                              //             setLogoImage({ ...logoImage, [field.name]: URL.createObjectURL(e.target.files[0]) });
                              //         } else {
                              //             setError([field.name], 'Invalid file type')
                              //         }
                              //     }
                              // }}
                              className="hidden"
                            />
                          </label>
                        </div>
                        {/* {getValues(field.name)?.length > 0 &&
                                                                        <>
                                                                            <div className='text-[14px] pt-5' style={{ color: 'rgba(0, 0, 0, 1)' }}>Uploaded Image </div>

                                                                            <div className='flex justify-between items-center w-[30%] mt-5 px-4 py-4'
                                                                                style={{ border: '1px solid rgba(29, 91, 191, 0.5)', borderRadius: '3px' }}>
                                                                                <div className='flex w-[80%] gap-3 items-center'>
                                                                                    <img src={UploadIcon} alt="altlogo" />
                                                                                    <span className='text-[12px]'> {getValues(imageField.name) && getValues(imageField.name)[0]?.name}


                                                                                    </span>
                                                                                </div>
                                                                                <img className='w-[30px] cursor-pointer' onClick={() => handleDeleteImage(field.name)} src={DeleteIcon} alt="DeleteIcon" />
                                                                            </div>



                                                                        </>
                                                                    } */}
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div
              className="text-primary-blue text-[18px] py-5 mb-8"
              style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
            >
              Client Secinary Information (option)
            </div>
            <div className="flex flex-wrap gap-4">
              {SeconaryRequestInfoFields.map((field, index) => {
                return (
                  <div className={`relative mb-6  ${field.width}`} key={index}>
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-normal mb-2"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    {field.type === "input" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          type={field.fieldType}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors[field.name]}
                        />
                        {field.icon && field.icon === "location" && (
                          <img
                            className="absolute top-4 right-4"
                            src={LocationIcon}
                            alt="LocationIcon"
                          />
                        )}

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="flex gap-6 my-10 justify-center align-middle">
              <Button
                btnName="Cancel"
                btnCategory="secondary"
                btnCls="w-[200px]"
                onClick={() => navigate("/")}
              />
              <Button
                btnType="submit"
                id={"program-submit"}
                btnCls="w-[200px]"
                btnName={"Submit"}
                btnCategory="primary"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
