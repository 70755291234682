import { createSlice } from "@reduxjs/toolkit";
import { getPartnerRequestById } from "../../services/partnerRequest";
import { getPartnerRequestByStatus } from "../../services/partnerRequest";
const initialState = {
  partnerRequest: [],
  status: "idle", 
  error: null,
};

const partnerRequestSlice = createSlice({
  name: "partnerRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerRequestById.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getPartnerRequestById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.partnerRequest = action.payload;
      })
      .addCase(getPartnerRequestById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      builder
      .addCase(getPartnerRequestByStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getPartnerRequestByStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.partnerRequest = action.payload;
      })
      .addCase(getPartnerRequestByStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default partnerRequestSlice.reducer;