import { createSlice } from "@reduxjs/toolkit";
import { createNewRequest, getAllCurrentRequestList, getAllPartnerRequestList } from "../../services/request";

const initialState = {
  data: [],
  details: {},
  loading: false,
  status: "",
  error: "",
};

export const requestSlice = createSlice({
  name: "requestInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewRequest.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createNewRequest.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(createNewRequest.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    
    
    builder
      .addCase(getAllCurrentRequestList.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCurrentRequestList.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          // status: categoryApiResponse.details,
          loading: false,
        };
      })
      .addCase(getAllCurrentRequestList.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

       
    
    builder
    .addCase(getAllPartnerRequestList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    })
    .addCase(getAllPartnerRequestList.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
        // status: categoryApiResponse.details,
        loading: false,
      };
    })
    .addCase(getAllPartnerRequestList.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    });
  },
});

export default requestSlice.reducer;
