import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../services/login";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const forgotData = useSelector((state) => state.loginInfo.data);
  const loading = useSelector((state) => state.loginInfo.loading);
  const apiError = useSelector((state) => state.loginInfo.error);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }

    try {
      await dispatch(forgotPassword(email)).unwrap();
      navigate(`/confirmotp/${email}`);
    } catch (error) {
      setError(apiError || "Failed to send forgot password request.");
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col max-w-[640px] border border-[#2260D9] px-7 gap-10 rounded-[5px] h-[635px]">
          <div className="flex flex-col items-center gap-5 py-10">
            <p className="font-bold text-[38px] text-primary-blue">CRRMS</p>
            <p className="font-medium text-[#353F4F] text-2xl px-28 text-center">
              Community Request and Referrals Management System
            </p>
          </div>
          <div className="flex flex-col pb-28">
            <div className="flex flex-col gap-1">
              <label htmlFor="email" className="text-[#353F4F] text-sm ">
                Enter your Official E-mail ID
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter your Official E-mail ID"
                className="text-[#353F4F] border-[1px] border-[#2260D9] text-sm focus:outline-none  rounded-[3px] placeholder:text-[#D1D1D1] placeholder:text-sm  py-4 px-[18px] w-full"
              />
            </div>
            {error && (
              <p className="font-light text-red text-[#DC3545] text-[14px] mt-1">
                {error}
              </p>
            )}

            <button
              className="bg-[#2260D9] text-white py-3 rounded-[3px] mt-14"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Sending..." : "Submit"}
            </button>
            <p className="text-[#2260D9] underline decoration-1 underline-offset-2 text-center mt-8">
              Login
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

