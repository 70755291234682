import {
    createAsyncThunk,
} from "@reduxjs/toolkit";

import api from "./api";


export const createNewAgent = createAsyncThunk(
    "createNewAgent",
    async (agentData, { rejectWithValue }) => {
        try {
            const createRequest = await api.post(`/agent/agent/`, agentData); 
            if (createRequest.status === 200 && createRequest.data) {
                return createRequest.data;
            }
            throw new Error("Failed to create agent");
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message); 
        }
    }
);

export const getAllAgentList = createAsyncThunk(
    "getAllAgentList",
    async () => {
        const getAllAgentInfo = await api.get('/agent/agent/');
        if (getAllAgentInfo.status === 200 && getAllAgentInfo.data) {
            const result =  getAllAgentInfo.data.result
            return result;
        }
        return getAllAgentInfo
    }
);

export const updateAgent = ({ data, agent_id, }) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_AGENT_REQUEST" });
        const response = await api.put(`/agent/agent/`, { ...data, agent_id, });
      dispatch({ type: "UPDATE_AGENT_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "UPDATE_AGENT_FAILURE", payload: error.message });
    }
  };


  export const updateAgentStatus = ({ status, agent_id, reason }) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_AGENT_REQUEST" });
        const response = await api.put(`/agent/agent/`, {  status, agent_id, reason });
      dispatch({ type: "UPDATE_AGENT_SUCCESS", payload: response.data });
    } catch (error) {   
      dispatch({ type: "UPDATE_AGENT_FAILURE", payload: error.message });
    }
  };
  

export const getAgentById = createAsyncThunk(
    "getAgentById",
    async (agentId) => {
        const getAgentById = await api.get('/agent/agent/', {
            params: {
                agent_id: agentId 
            }
        });
        if (getAgentById.status === 200 && getAgentById.data) {
            const result =  getAgentById.data.result
            return result;
        }
        return getAgentById
    }
);

export const getTotalRequestAgentById = createAsyncThunk(
    "getTotalRequestAgentById",
    async (agentId) => {
        const getTotalRequestAgentById = await api.get('/agent/', {
            params: {
                agent_id: agentId 
            }
        });
        if (getTotalRequestAgentById.status === 200 && getTotalRequestAgentById.data) {
            const result =  getTotalRequestAgentById.data.result
            return result;
        }
        return getTotalRequestAgentById
    }
);