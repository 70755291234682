import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import {
  AddNewCategoryFields,
  CategoryManagerDetailField,
} from "../../utills/formFields";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { getAddNewCategory } from "../../services/category";
import { useDispatch, useSelector } from "react-redux";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function AddNewCategory() {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getFieldState,
    getValues,
    setError,
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddNewCategory);
  }, [dispatch]);

  const [imageFiles, setImageFiles] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };
  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };

  // useEffect(() => {
  //   reset({ urgency_level: "low" });
  // }, []);

  const onSubmit = (data) => {
   
    const editData = {
      // id: data.id,
      name: data.category_title,
      beneficiaries: data.maximum_number_of_beneficiaries,
      category_type: data.category_type,
      urgency_level: data.urgency_level,
      category_manager: data.category_manager,
      manager_contact_details: data.manager_contact_details,
      description: data.description,
      status: data.status,
      // images: imageFiles,
    };

    console.log("editdata", editData);
    try {
      dispatch(getAddNewCategory({ categoryData: editData })).unwrap();
      // setTimeout(() => {
      //   navigate("/allcategory");
      // }, 2000);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <div className="px-8 pt-10 bg-[#F9F9F9]">
      <BreadCumbs
        firstCumbName="Dashboard"
        secoundCumbName="Category"
        pathToRedirect="allcategory"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white custom-shadow  rounded-[5px] pt-10 my-10 px-3">
          <div>
            <div className="border-b border-[#E4E6EF] text-primary-blue text-[18px] pl-6 pb-4">
              Add New Category
            </div>

            <div className="flex flex-wrap gap-4 px-8 pt-10">
              {AddNewCategoryFields.map((field, index) => {
                return (
                  <div className={`relative mb-6  ${field.width}`} key={index}>
                    <label
                      className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    {field.type === "input" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          type={field.fieldType}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors[field.name]}
                        />

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : (
                      <>
                        <select
                          {...register(field.name, field.inputRules)}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                            borderRight: "16px solid transparent",
                          }}
                        >
                          <option value="">Select</option>
                          {field.options.map((option, index) => {
                            return (
                              <option value={option.value} key={index}>
                                {option.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pt-14">
            <div className="pl-6 pb-4 text-primary-blue text-[18px] border-b border-[#E4E6EF]">
              Category Manager Details
            </div>

            <div className="flex flex-wrap gap-4 px-8 pt-10">
              {CategoryManagerDetailField.map((field, index) => {
                return (
                  <div
                    className={`relative mb-6  ${field.width} ${field.height}`}
                    key={index}
                  >
                    <label
                      className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    {field.type === "input" ? (
                      <div className="relative">
                        <input
                          {...register(field.name, field.inputRules)}
                          type={field.fieldType}
                          className={`w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px] ${field.height}`}
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                          }}
                          aria-invalid={!!errors[field.name]}
                        />

                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </div>
                    ) : (
                      <>
                        <select
                          {...register(field.name, field.inputRules)}
                          className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg 
                                                                focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                          placeholder={field.placeholder}
                          style={{
                            color: "#232323",
                            borderRadius: "3px",
                            borderRight: "16px solid transparent",
                          }}
                        >
                          <option value="">Select</option>
                          {field.options.map((option, index) => {
                            return (
                              <option value={option.value} key={index}>
                                {option.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors[field.name] && (
                          <p className="error" role="alert">
                            {errors[field.name].message}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
              <div className="w-full">
                <label className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2">
                  Category Logo (Optional)
                </label>

                <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                  <Stack direction="row" spacing={1}>
                    {imageFiles.map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => handleDelete(file)}
                        sx={{
                          backgroundColor: "#F0F0F0",
                          color: "#2260D9",
                          ".MuiChip-deleteIcon": {
                            color: "#FF0000",
                          },
                        }}
                      />
                    ))}
                  </Stack>
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    multiple
                    className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                    id="image-upload"
                    style={{
                      color: "#232323",
                      borderRadius: "3px",
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />
                  <button
                    className="bg-[#2260D9] text-white rounded-[2px] p-2"
                    onClick={() =>
                      document.getElementById("image-upload").click()
                    }
                  >
                    <AddIcon className="" />
                    Upload Image
                  </button>
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center gap-10 mt-16 pb-20">
              <button className="border-[#353F4F] border-[1px] text-[#353F4F] rounded-[3.9px] w-[195px] h-[45px] ">
                Cancel
              </button>
              <button
                type="submit"
                className="border-[#353F4F] border-[1px] rounded-[3.9px] w-[195px] h-[45px] bg-[#2260D9] text-white"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
