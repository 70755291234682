import { createSlice } from "@reduxjs/toolkit";
import { getEmployeesDetails } from "../../services/employees";

const initialState = {
  details: {},
  loading: false,
  status: "",
  error: "",
};

export const employeeSlice = createSlice({
  name: "employeeInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getEmployeesDetails.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getEmployeesDetails.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(getEmployeesDetails.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
  },
});

export default employeeSlice.reducer
