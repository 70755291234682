import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import SearchIcon from "../../assets/input-search-icon.svg";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import moment from "moment";
import DataTable from "../../shared/DataGrid";
import { RequestViaTab } from "../../utills/formFields";
import { getCurrentRequest } from "../../services/currentRequest";
import { createCurrentRequest } from "../../services/currentRequest";
import { useSearchParams } from "react-router-dom";

const AgentRequestVia = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionTab, setActiveTab] = useState("");
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  const [searchParams] = useSearchParams();
  const filterType = searchParams.get("status");

  const navigate = useNavigate();

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  // };

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterType) {
      setActiveTab(filterType);
    }
  }, [filterType])

  useEffect(() => {
    dispatch(createCurrentRequest());
  }, [dispatch]);
  

  useEffect(() => {
    dispatch(
      getCurrentRequest({
        // page,
        // limit,
        request_via: actionTab,
        status: ""
      })
    );
  }, [dispatch, actionTab, filterType]);

  const currentData = useSelector((state) => state.currentRequest.currentData);
  const allData = useSelector((state) => state.currentRequest.allData);
 

  const rows =
    currentData && currentData.length > 0
      ? currentData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category: item.id,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.request_status_desc,
          submittedDate: item.updated_at,
          status: item.request_status,
          response: item.partner_status,
        }))
      : [];

  const requestColumnn = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
    },
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => (
        <div className="capitalize">{params.value}</div>
      )
    },

    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
      },
    },

    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => (
        <div className="capitalize">{params.value}</div>
      )
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "submittedDate",
      headerName: "Submitted Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]"
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "partner",
      headerName: "Partner",
      flex: 1,
    },
    {
      field: "response",
      headerName: "Partner Response",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          accepted: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]," 
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,

      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block mt-2 text-left">
            <div>
              <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/request-tracking`);
                  }}
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  return (
    <>
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <BreadCumbs firstCumbName="Request" secoundCumbName="Request Status" />
        <p className="text-[#18283D] text-[18px] mt-12 mb-5">Request</p>

        <div>
          <div className="px-8 py-10 mb-10 custom-shadow rounded-[5px]">
            <div className="details-info">
              <div className="flex justify-between mb-8 items-center border-b border-[#D9D9D9]">
                <ul className="tab-list">
                  {RequestViaTab.map((discussion, index) => (
                    <li
                      className={`${
                        actionTab === discussion.key ? "active" : ""
                      } relative`}
                      style={{ width: "150px" }}
                      key={index}
                      onClick={() => setActiveTab(discussion.key)}
                    >
                      <div className="flex justify-center pb-1">
                        <div
                          className={`total-proram-count relative ${
                            actionTab === discussion.key ? "active" : ""
                          }`}
                        >
                        
                          {allData?.data?.filter((data) =>
                            discussion.name !== "Total Request"
                              ? data?.request_via === discussion.key
                              : true
                          )?.length || 0}
                        </div>
                      </div>
                      <div className="text-[13px]">{`${discussion.name}`}</div>
                      {actionTab === discussion.key && <span></span>}
                    </li>
                  ))}
                </ul>
                <div className="relative">
                  <input
                    type="text"
                    id="search-navbar"
                    className="block w-full p-2 text-sm text-gray-900 border-none"
                    placeholder="Search here..."
                    style={{
                      border: "1px solid rgba(34, 96, 217, 1)",
                      height: "50px",
                      width: "390px",
                    }}
                  />
                  <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              rows={rows || []}
              columns={requestColumnn}
              hideFooter
              hideCheckbox
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentRequestVia;
