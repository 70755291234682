import React, { useEffect } from "react";
import SearchIcon from "../../assets/search-icon.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { getAllPartnerRequestList } from "../../services/request";
import { useDispatch, useSelector } from "react-redux";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import moment from "moment";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import {
  getPartnerRequestById,
  getPartnerRequestByStatus,
} from "../../services/partnerRequest";
import { useSearchParams } from "react-router-dom";

function PartnerRequestsView() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionTab, setActiveTab] = useState("all");

  const userData = useSelector((state) => state.userInfo.data);
  const userId = userData.id;
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const RequestTabs = [
    {
      name: "All",
      key: "all",
    },
    {
      name: "New",
      key: "new",
    },
    {
      name: "Accepted",
      key: "accepted",
    },
    {
      name: "Completed",
      key: "completed",
    },
    {
      name: "Rejected",
      key: "rejected",
    },
    {
      name: "Important",
      key: "important",
    },
  ];

  const columns = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
    },
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "requestedBy",
      headerName: "Requested By",
      flex: 1,
      rendercell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "receivedDate",
      headerName: "Received Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "response",
      headerName: "Your Response",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          accepted: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          completed: "text-[#1D5BBF],",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "requestStatus",
      headerName: "Request Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/requests/:requestId`);
                  }}
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    share
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };
  const currentRequestData = useSelector(
    (state) => state.requestInfo?.data?.result?.data
  );

  console.log(currentRequestData, currentRequestData);

  useEffect(() => {
    if (userId) {
      dispatch(getAllPartnerRequestList(userId));
    }
  }, [dispatch, userId]);

  console.log(id, status,'userId')

  useEffect(() => {
    if (status) {
      setActiveTab(status);
    }
  }, [status])

  useEffect(() => {
    if (id && actionTab) {
      dispatch(
        getPartnerRequestByStatus({
          id,
          status: actionTab,
        })
      );
    }
  }, [dispatch, id, actionTab,status]);

   

  const rows =
    currentRequestData && currentRequestData.length > 0
      ? currentRequestData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.contact_firstname || "N/A",
          category: item.category || "N/A",
          contact: item.contact || "N/A",
          via: item.request_via || "N/A",
          intakeDate: item.created_at
            ? new Date(item.created_at).toLocaleString()
            : "N/A",
          level: item.urgency_level || "N/A",
          description: item.description || "No description provided",
          receivedDate: item.updated_at
            ? new Date(item.updated_at).toLocaleString()
            : "N/A",
          requestStatus: item.request_status || "Unknown",
          response: item.partner_status || "Pending",
        }))
      : [];

  return (
    <main className="p-10">
      <p>Request</p>
      <div className="mt-7 p-2 shadow-lg rounded details-info">
        <div className="p-4 flex items-center justify-between">
          <h2 className="text-2xl font-semibold">Request</h2>
          <div className="flex gap-8">
            <div className="relative">
              <input
                type="text"
                className="block w-full p-2 text-sm text-gray-900 border-none"
                placeholder="Search here..."
                style={{
                  border: "1px solid rgba(29, 91, 191, 1)",
                  borderRadius: "3px",
                  height: "58px",
                  width: "350px",
                }}
                onChange={(e) => console.log(e)}
              />
              <div className="absolute right-2 top-3">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className="border-t-2 border-t-gray-bg my-2"></div>
        <div className="flex justify-between px-5 mb-8 items-center border-b">
          <ul className="tab-list">
            {RequestTabs?.map((discussion, index) => (
              <li
                className={`${
                  actionTab === discussion.name ? "active" : ""
                } relative`}
                key={index}
                onClick={() => setActiveTab(discussion.key)}
              >
                <div className="flex justify-center pb-1">
                  <div
                    className={`total-proram-count relative ${
                      actionTab === discussion.key ? "active" : ""
                    }`}
                  >
                    {rows.filter((data) =>
                      discussion.key !== "all"
                        ? data?.requestStatus === discussion.key
                        : true
                    )?.length || 0}
                  </div>
                </div>
                <div className="text-[13px]"> {`${discussion.name}`}</div>
                {actionTab === discussion.key && <span></span>}
              </li>
            ))}
          </ul>
        </div>
        <div className="p-4 custom-shadow">
          <DataGrid
            columns={columns}
            rows={
              rows.filter((data) =>
                actionTab !== "all" ? data?.requestStatus === actionTab : data
              ) || []
            }
          />
        </div>
      </div>
    </main>
  );
}

export default PartnerRequestsView;
