import React, { useState } from "react";
import MoreIcon from "../../assets/more-icon.svg";
import { Menu, MenuItem } from "@mui/material";
import UserImage from "../../assets/images/user.png";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import { useParams } from "react-router";

const AgentProfile = ({ agentInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {id} = useParams;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <div className="px-8">
      <div className="pt-10 px-8 my-10 border border-[#2260D9] rounded-[5px]">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-2xl font-medium text-[#353F4F]">Agent Profile</h2>
          <div
            className="py-2 px-4 border border-[#D9D9D9] rounded-md"
            onClick={(e) => handleClick(e)}
          >
            <img src={MoreIcon} alt="MoreIcon" />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className="shadow-none h-full w-full"
          >
            <MenuItem className="!text-[12px] text-[#353F4F]">Edit</MenuItem>
            <MenuItem className="!text-[12px] text-[#353F4F]">Deactivate</MenuItem>
          </Menu>
        </div>

        <div className="grid grid-cols-12 items-center py-2 px-4 mt-10 border border-[#D9D9D9] rounded-md">
          <div className="col-span-2 flex pl-3 items-center w-full">
            <img
              className="rounded-full h-36 w-36 p-2 border border-[#D9D9D9]"
              src={UserImage}
              alt="UserImage"
            />
          </div>
          <div className="col-span-7 pl-8">
            <p className="text-4xl font-medium text-[#353F4F]">
              {" "}
              {`${agentInfo?.first_name} ${agentInfo?.last_name}`}
            </p>
            <p className="text-lg font-medium text-[#353F4F]">Agent</p>
          </div>
          <div className="col-span-3 flex pr-3 justify-end items-center h-full w-full">
            {agentInfo?.status === "active" ? (
              <button
                className={`h-10 rounded-[30px] py-2 px-5 border border-[#35A462] text-[#35A462] bg-[#EDFFF4]`}
              >
                Active
              </button>
            ) : (
              <button
                className={`h-10 rounded-[30px] py-2 px-5 border border-[#DC3545] text-[#DC3545] bg-[#FFF3F4]`}
              >
                Inctive
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-col w-full  py-2 px-4 mt-10 border border-[#D9D9D9] rounded-md">
          <div className="text-2xl font-medium text-[#353F4F]">
            Personal Information
          </div>
          <div className="grid grid-cols-12 gap-8 mt-8">
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">
                First Name
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.first_name}
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">
                Last Name
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.last_name}
              </div>
            </div>
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">Email</div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.email}
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">Phone</div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.contact_number}
              </div>
            </div>
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">Bio</div>
              <div className="text-lg font-semibold text-[#353F4F]">
                ABOCE Agent
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">
                Description
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                The purpose of lorem ipsum is to create a natural looking block
                of text (sentence, paragraph, page, etc.)
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full  py-2 px-4 mt-10 border border-[#D9D9D9] rounded-md">
          <div className="text-2xl font-medium text-[#353F4F]">Address</div>
          <div className="grid grid-cols-12 gap-8 mt-8">
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">Country</div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.country}
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">
                City/State
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.city},{agentInfo?.state}
              </div>
            </div>
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">
                Postal Code
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.postal_code}
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">Phone</div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.alternative_number}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full  py-2 px-4 mt-10 border border-[#D9D9D9] rounded-md">
          <div className="text-2xl font-medium text-[#353F4F]">
            Organization Contact Information
          </div>
          <div className="grid grid-cols-12 gap-8 mt-8">
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">
                First Name
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.organization?.first_name}
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">
                Last Name
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.organization?.last_name}
              </div>
            </div>
            <div className="col-span-3">
              <div className="text-lg font-normal text-[#353F4F]">Email</div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.organization?.email}
              </div>
            </div>
            <div className="col-span-9">
              <div className="text-lg font-normal text-[#353F4F]">
                Primary Contact Number
              </div>
              <div className="text-lg font-semibold text-[#353F4F]">
                {agentInfo?.organization?.org_phone_number}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AgentProfile;
