export const PrimaryInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "Enter your first name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Enter your last name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "primary_contact_number",
    label: "Primary Contact Number",
    placeholder: "Enter Primary Contact Number",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "secondary_contact_number",
    label: "Secondary Contact Number",
    placeholder: "Enter Secondary Contact Number",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Enter your Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "agent_status",
    label: "Agent Status",
    placeholder: "Select request Category",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "active",
        value: "Active",
      },
      {
        name: "inactive",
        value: "InActive",
      },
    ],
    width: "w-[49%]",
  },
];

export const editstatus = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "status",
    label: "Agent Status",
    placeholder: "Select request Category",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Inactive",
        value: "inactive",
      },
    ],
    width: "w-[35%]",
  },
];

export const partnerstatus = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "status",
    label: "Partner Status",
    placeholder: "Select request Category",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Inactive",
        value: "inactive",
      },
    ],
    width: "w-[35%]",
  },
];

export const RequestDetailsFields = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "category",
    label: "Request Category",
    placeholder: "Select request Category",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Category1",
        value: "category1",
      },
      {
        name: "Category 2",
        value: "category2",
      },
      {
        name: "Category3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "beneficiary",
    label: "Number of Beneficiaries",
    placeholder: "Number of Beneficiaries",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "radio",
    fieldType: "radio",
    name: "urgency_level",
    label: "Urgency Level",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "High",
        value: "high",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Low",
        value: "low",
      },
    ],
    width: "w-full",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "desc",
    label: "Description",
    placeholder: "Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "file",
    fieldType: "file",
    name: "attachment",
    label: "Attachments (optional)",
    placeholder: "Upload attachment",
    width: "w-full",
  },
];

export const PrimaryRequestInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "contact",
    label: "Primary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "alternative_number",
    label: "Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
];

export const NewRequestDetailsFields = [
  {
    type: "radio",
    fieldType: "radio",
    name: "request_via",
    label: "Request via",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Call",
        value: "call",
      },
      {
        name: "Walk-in",
        value: "walk_in",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "category",
    label: "Request Category",
    placeholder: "Select request Category",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Category1",
        value: "category1",
      },
      {
        name: "Category 2",
        value: "category2",
      },
      {
        name: "Category3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "beneficiaries",
    label: "Number of Beneficiaries",
    placeholder: "Number of Beneficiaries",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "radio",
    fieldType: "radio",
    name: "urgency_level",
    label: "Urgency Level",
    placeholder: "Enter Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "High",
        value: "high",
      },
      {
        name: "Medium",
        value: "medium",
      },
      {
        name: "Low",
        value: "low",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "textbox",
    fieldType: "text",
    name: "description",
    label: "Description",
    placeholder: "Enter Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const SeconaryRequestInfoFields = [
  {
    type: "input",
    fieldType: "text",
    name: "contact_firstname",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "contact_lastname",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "Smith",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "contact_number",
    label: "Primary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "secondary_contact_number",
    label: "Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "contact_address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
];

export const ProfileFields = [
  {
    type: "input",
    fieldType: "text",
    name: "name",
    label: "Name",
    placeholder: "Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "phone_number",
    label: "Phone Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "email",
    name: "email",
    label: "Email Id",
    placeholder: "email@gmail.com",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
];

export const AllRequestTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Completed",
    key: "completed",
  },
  {
    name: "On Hold",
    key: "on-hold",
  },
  {
    name: "Rejected",
    key: "rejected",
  },
];

export const PartnersTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Active",
    key: "active",
  },
  {
    name: "Inactive",
    key: "inactive",
  },
];

export const CategoryTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Active",
    key: "active",
  },
  {
    name: "Inactive",
    key: "inactive",
  },
];

export const ExternalCategoryTab = [
  {
    name: "Total Partners",
    key: "totalpartners",
  },
  {
    name: "Total Request",
    key: "totalrequest",
  },
];

export const AgentTab = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Active",
    key: "active",
  },
  {
    name: "Inactive",
    key: "inactive",
  },
];

export const RequestViaTab = [
  {
    name: "Total Request",
    key: "",
  },

  {
    name: "Call",
    key: "call",
  },

  {
    name: "Online",
    key: "email",
  },

  {
    name: "Walk-In",
    key: "walk_in",
  },
];

export const AddNewCategoryFields = [
  {
    type: "input",
    fieldType: "text",
    name: "category_title",
    label: "Category Title",
    placeholder: "Category Title",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "maximum_number_of_beneficiaries",
    label: "Maximum Number of Beneficiaries ",
    placeholder: "Number of Beneficiaries",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "category_type",
    label: "Category Type",
    placeholder: "Internal / External ",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Category1",
        value: "category1",
      },
      {
        name: "Category 2",
        value: "category2",
      },
      {
        name: "Category3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "dropdown",
    fieldType: "select",
    name: "urgency_level",
    label: "Urgency Level",
    placeholder: "Urgency Level",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Urgency1",
        value: "Urgency1",
      },
      {
        name: "Urgency 2",
        value: "Urgency2",
      },
      {
        name: "Urgency3",
        value: "Urgency3",
      },
    ],
    width: "w-[49%]",
  },
];

export const CategoryManagerDetailField = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "category_manager",
    label: "Category Manager",
    placeholder: "Select Category Manager",
    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Category1",
        value: "category1",
      },
      {
        name: "Category 2",
        value: "category2",
      },
      {
        name: "Category3",
        value: "category3",
      },
    ],
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "manager_contact_details",
    label: "Manager Contact Details ",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "textarea",
    name: "category_description",
    label: "Category Description",
    placeholder: "Category Description",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
    height: "h-[200px]",
  },
];

export const AddNewPartnerFields = [
  {
    type: "input",
    fieldType: "text",
    name: "organization_name",
    label: "Organization Name",
    placeholder: "Organization Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "email",
    label: "E-Mail",
    placeholder: "email@gmail.com",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "tel",
    name: "contact_number",
    label: "Primary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "total_org_employee",
    label: "Total Employees",
    placeholder: "49",
    inputRules: {},
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "org_address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "city",
    label: "City",
    placeholder: "City",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "state",
    label: "State",
    placeholder: "State",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "postal_code",
    label: "Postal Code",
    placeholder: "12345",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "country",
    label: "Country",
    placeholder: "USA",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
];
export const OrganizationContactPersonFields = [
  {
    type: "input",
    fieldType: "text",
    name: "organization.first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "organization.last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "organization.email",
    label: "E-Mail",
    placeholder: "email@gmail.com",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "organization.org_phone_number",
    label: "Primary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
];

export const AddNewAgentFields = [
  {
    type: "input",
    fieldType: "text",
    name: "first_name",
    label: "First Name",
    placeholder: "First Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[32%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "last_name",
    label: "Last Name",
    placeholder: "Last Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[32%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "email",
    label: "E-Mail",
    placeholder: "email@gmail.com",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[33%]",
  },
  {
    type: "input",
    fieldType: "tel",
    name: "contact_number",
    label: "Primary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "tel",
    name: "alternative_number",
    label: "Secondary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "address",
    label: "Address",
    placeholder: "Address",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "city",
    label: "City",
    placeholder: "City",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },

  {
    type: "input",
    fieldType: "text",
    name: "state",
    label: "State",
    placeholder: "State",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "number",
    name: "postal_code",
    label: "Postal Code",
    placeholder: "12345",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "country",
    label: "Country",
    placeholder: "USA",
    inputRules: {
      required: "This field is required   ",
    },
    width: "w-[49%]",
  },
];

export const OrgannizationAgentFields = [
  {
    type: "input",
    fieldType: "text",
    name: "organization.first_name",
    label: "Name",
    placeholder: "Name",
    inputRules: {
      required: "This field is required",
    },
    width: "w-full",
  },
  {
    type: "input",
    fieldType: "text",
    name: "organization.email",
    label: "E-Mail",
    placeholder: "email@gmail.com",
    inputRules: {
      required: "This field is required",
    },
    width: "w-[49%]",
  },
  {
    type: "input",
    fieldType: "text",
    name: "organization.org_phone_number",
    label: "Primary Contact Number",
    placeholder: "(123) 456-7890",
    inputRules: {
      required: "This field is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Invalid Phone number",
      },
    },
    width: "w-[49%]",
  },
];

export const categoryStatus = [
  {
    type: "dropdown",
    fieldType: "select",
    name: "status",
    label: "Category Status",
    placeholder: "Select request Category",

    inputRules: {
      required: "This field is required",
    },
    options: [
      {
        name: "Active",
        value: "active",
      },
      {
        name: "Inactive",
        value: "inactive",
      },
    ],
    width: "w-[35%]",
  },
];
