import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const loginData = createAsyncThunk("LoginData", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post("/user/login/", data);
    console.log("login", response);

    if (response.status === 200 && response.data) {
      const result = response.data.result || {};
      const accessToken = result.access_token || "";
      
      if (accessToken) {
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem('userType',"admin")
      }
      
      return result;
    }

    throw new Error("Login failed. Please check your credentials.");
  } catch (error) {
    return rejectWithValue(error.message || "Something went wrong.");
  }
});





export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await api.post("/user/forgot-password/", { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Something went wrong."
      );
    }
  }
);


export const verifyResetCode = createAsyncThunk(
  "user/verifyResetCode", 
  async ({ reset_code, email },{ rejectWithValue }) => {  
    console.log("API call initiated witreset_code:", reset_code);
    try {
      const response = await api.post("/user/verify-reset-code/", { reset_code, email });
      console.log("API response:", response);
      return response.data;
    } catch (error) {
      console.error("API error:", error.response?.data); 
      return rejectWithValue(
        error.response?.data?.message || "Something went wrong."
      );
    }
  }
);


// export const setNewPassword = createAsyncThunk(
//   "user/setNewPassword",
//   async ({  new_password, confirm_password, token }, { getState, rejectWithValue }) => {
//     console.log("API call initiated with payload:", {  new_password, confirm_password, });
//     const token = getState().loginInfo.data.result.token;  

   
//     if (!token) {
//       return rejectWithValue("Token is missing. Please login again.");
//     }

//     try {
//       const response = await api.post("/user/set-password/", {
//         token,
//         new_password,
//         confirm_password,
//       });
//       console.log("API response:", response);
//       return response.data;
//     } catch (error) {
//       console.error("API error:", error.response?.data);
//       return rejectWithValue(
//         error.response?.data?.message || "Something went wrong."
//       );
//     }
//   }
// );

export const setNewPassword = createAsyncThunk(
  "user/setNewPassword",
  async ({ new_password, confirm_password, token }, { rejectWithValue }) => {
    if (!token) {
      return rejectWithValue("Token is missing. Please login again.");
    }

    try {
      const response = await api.post("/user/set-password/", {
        token,
        new_password,
        confirm_password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Something went wrong.");
    }
  }
);


