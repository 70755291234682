export const RequestColumn = [
  {
    field: "full_name",
    headerName: "Request ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "No. of Beneficiaries",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Via",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Submitted Date",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Level",
    flex: 1,
    id: 2,
  },
  {
    field: "last_attend_program",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "last_attend_program",
    headerName: "Action",
    flex: 1,
    id: 1,
  },
];

export const FrequentRequestColumn = [
  {
    field: "id",
    headerName: "Category ID",
    flex: 1,
    id: 0,
  },
  {
    field: "name",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "partner_count",
    headerName: "Total Partners",
    flex: 1,
    id: 2,
  },
  {
    field: "request_count",
    headerName: "Total Request",
    flex: 1,
    id: 1,
  },
];

export const CurrentStatusColumn = [
  {
    field: "full_name",
    headerName: "OPT",
    flex: 1,
    id: 0,
  },
  {
    field: "id",
    headerName: "Request ID",
    flex: 1,
    id: 1,
  },
  {
    field: "first_name",
    headerName: "Client Name",
    flex: 1,
    id: 2,
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    id: 3,
  },
  {
    field: "contact",
    headerName: "Contact",
    flex: 1,
    id: 4,
  },
  {
    field: "request_via",
    headerName: "Via",
    flex: 1,
    id: 5,
  },
  {
    field: "intakeDate",
    headerName: "Intake Date",
    flex: 1,
    id: 6,
  },
  {
    field: "level",
    headerName: "Level",
    flex: 1,
    id: 7,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    id: 8,
  },
  {
    field: "submittedDate",
    headerName: "Submitted Date",
    flex: 1,
    id: 9,
  },
  {
    field: "request_status",
    headerName: "Status",
    flex: 1,
    id: 10,
  },
  {
    field: "partners",
    headerName: "Partner",
    flex: 1,
    id: 11,
  },
  {
    field: "partner_status",
    headerName: "Partner Response",
    flex: 1,
    id: 12,
  },
];

export const PartnerColumn = [
  {
    field: "partner_id",
    headerName: "Partner ID",
    flex: 1,
    id: 0,
  },
  {
    field: "full_name",
    headerName: "Organization Name",
    flex: 1,
    id: 1,
  },
  {
    field: "partner_type",
    headerName: "Partner Type",
    flex: 1,
    id: 2,
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "totle_employees",
    headerName: "Total Employees",
    flex: 1,
    id: 1,
  },
  {
    field: "managed_category",
    headerName: "Managed Category",
    flex: 1,
    id: 2,
  },
  
];

export const CategoryColumn = [
  {
    field: "id",
    headerName: "Category ID",
    flex: 1,
    id: 0,
  },
  {
    field: "category",
    headerName: "Category",
    flex: 2,
    id: 1,
  },
  {
    field: "category_type",
    headerName: "Catogory Type",
    flex: 1,
    id: 2,
  },
  {
    field: "available_partner",
    headerName: "Available Partner",
    headerAlign:"center",
    align: "center",
    flex: 1,
    id: 3,
  },
  {
    field: "managed_request",
    headerName: "Managed Request",
    headerAlign:"center",
    align: "center",
    flex: 1,
    id: 4,
  },
 
];

export const AgentColumn = [
  {
    field: "agent_id",
    headerName: "Agent ID",
    flex: 1,
    id: 0,
  },
  {
    field: "full_name",
    headerName: "Agent Name",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    id: 2,
  },
  {
    field: "phone_number",
    headerName: "Contact",
    flex: 1,
    id: 3,
  },
  {
    field: "managed_partners",
    headerName: "Managed Partners",
    flex: 1,
    id: 4,
  },
  {
    field: "managed_categories",
    headerName: "Managed Categories",
    flex: 1,
    id: 5,
  },
  
];

export const TotalRequestColumn = [
  {
    field: "full_name",
    headerName: "OPT",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Request ID",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Client Name",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Contact",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Via",
    flex: 1,
    id: 2,
  },
  {
    field: "intake_date",
    headerName: "Intake Date",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Level",
    flex: 1,
    id: 2,
  },
  {
    field: "desc",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "submit",
    headerName: "Submitted Date",
    flex: 1,
    id: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 1,
  },
  {
    field: "partner",
    headerName: "Partner",
    flex: 1,
    id: 1,
  },
  {
    field: "response",
    headerName: "Partner Response",
    flex: 1,
    id: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    id: 1,
  },
];

export const TotalPartnerColumn = [
  {
    field: "full_name",
    headerName: "Partner ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Name",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Total Employees",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Managed Category",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Last Update Date",
    flex: 1,
    id: 2,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    id: 2,
  },
];

export const TotalAgentColumn = [
  {
    field: "agent_id",
    headerName: "Agent ID",
    flex: 1,
    id: 0,
  },
  {
    field: "agent_name",
    headerName: " Agent Name",
    flex: 1,
    id: 1,
  },
  {
    field: "total_employees",
    headerName: "Total Employees",
    flex: 1,
    id: 2,
  },
  {
    field: "managed_category",
    headerName: "Managed Category",
    flex: 1,
    id: 1,
  },
  {
    field: "last_update_date",
    headerName: "Last Update Date",
    flex: 1,
    id: 2,
  },
];

export const TotalEmployeesColumn = [
  {
    field: "full_name",
    headerName: "Agent Name",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Email",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Contact",
    flex: 1,
    id: 2,
  },
];

export const TopAgentColumn = [
  {
    field: "request",
    headerName: "Total Request",
    headerAlign: "center",
    align: "center",
    flex: 1,
    id: 1,
  },
];

export const TopPartnerColumn = [
  {
    field: "name",
    headerName: "Partner Name",
    flex: 1,
    id: 1,
  },

  {
    field: "request",
    headerName: "Total Request",
    headerAlign: "center",
    align: "center",
    flex: 1,
    id: 1,
  },
];

export const ExternalCategoryColumn = [
  {
    field: "partnerId",
    headerName: "Partner ID",
    flex: 1,
    id: 1,
  },

 { field: "name",
  headerName: "Name",
  flex: 1,
  id: 5,
},{
field: "manageCategory",
headerName: "Managed Category",
flex: 1,
id: 5,
},


]
