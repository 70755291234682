import React, { useState } from "react";

import MuiModal from "../../shared/Modal";
import Tooltip from "../../shared/Tooltip";

import ProfileUserImage from "../../assets/images/profile-image.jpeg";
import CloseIcon from "../../assets/close-red-icon.svg";
import PencilIcon from "../../assets/pencil-icon.svg";
import { useForm } from "react-hook-form";
import { ProfileFields } from "../../utills/formFields";
import { Button } from "../../shared/Button";

export default function MyProfile({ open, closeModal }) {
  const [actionMode, setActionMode] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log("Submit", data);
  };

  return (
    <MuiModal modalSize="sm" modalOpen={open} modalClose={closeModal} noheader>
      <div
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "5px",
          border: "1px solid rgba(34, 96, 217, 1)",
        }}
      >
        <div
          className="title py-3 px-8"
          style={{ borderBottom: "1px solid rgba(34, 96, 217, 1)" }}
        >
          <div className="flex items-center justify-end">
            <Tooltip title="Cancel">
              <img
                src={CloseIcon}
                className="cursor-pointer"
                onClick={closeModal}
                alt="CloseIcon"
              />
            </Tooltip>
          </div>

          <div className="flex flex-col justify-center items-center gap-6 relative">
            <img
              src={ProfileUserImage}
              alt="ProfileUserImage"
              style={{ borderRadius: "50%", height: "100px" }}
            />
            {actionMode === "edit" && (
              <div
                className="bg-white absolute top-[70%] right-[42%]  px-2 py-2"
                style={{
                  borderRadius: "50%",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <img src={PencilIcon} alt="PencilIcon" />
              </div>
            )}

            {actionMode === "" && (
              <>
                <h2 className="text-[24px] font-bold">John Doe</h2>
                <p>Phone Number: 0123456789</p>
                <p>Email id: abc@gmail.com</p>
                <p>Address: San Francisco, Aemrica </p>
                <button
                  style={{
                    border: "1px solid rgba(34, 96, 217, 1)",
                    borderRadius: "4px",
                  }}
                  className="py-3 mt-5 mb-7 px-7 w-[150px]"
                  onClick={() => setActionMode("edit")}
                >
                  Edit
                </button>
              </>
            )}
          </div>

          {actionMode === "edit" && (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap gap-4">
                  {ProfileFields.map((field, index) => {
                    return (
                      <div
                        className={`relative mb-6 ${field.width}`}
                        key={index}
                      >
                        <label
                          className="block tracking-wide text-gray-700 text-xs font-normal mb-2"
                          htmlFor={field.label}
                        >
                          {field.label}{" "}
                          <span style={{ color: "red" }}>
                            {field?.inputRules?.required ? "*" : ""}
                          </span>
                        </label>
                        <div className="relative">
                          <input
                            {...register(field.name, field.inputRules)}
                            type={field.fieldType}
                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                            placeholder={field.placeholder}
                            style={{
                              color: "#232323",
                              borderRadius: "3px",
                            }}
                            aria-invalid={!!errors[field.name]}
                          />

                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex gap-6 my-10 justify-center align-middle">
                  <Button
                    btnName="Cancel"
                    btnCategory="secondary"
                    btnCls="w-[200px]"
                    onClick={() => setActionMode("")}
                  />
                  <Button
                    btnType="submit"
                    id={"program-submit"}
                    btnCls="w-[200px]"
                    btnName={"Submit"}
                    btnCategory="primary"
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </MuiModal>
  );
}
