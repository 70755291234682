import React, { useEffect, useState } from "react";
import DataTable from "../../shared/DataGrid";
// import { getCurrentRequest } from "../../services/currentRequest";
import {
  createCurrentRequest,
  getCurrentRequest,
} from "../../services/currentRequest";
import { useDispatch, useSelector } from "react-redux";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import SearchIcon from "../../assets/input-search-icon.svg";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import moment from "moment";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

const CurrentRequest = () => {
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const requestColumnn = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
    },
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },

    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => (
        <div className="capitalize">{params.value}</div>
      )
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => (
        <div className="capitalize">{params.value}</div>
      )
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "submittedDate",
      headerName: "Submitted Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]"
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },

    {
      field: "partner",
      headerName: "Partner",
      flex: 1,
    },
    {
      field: "response",
      headerName: "Partner Response",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          accepted: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]," 
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,

      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/request-tracking`);
                  }}
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  // };

  // useEffect(() => {
  //   dispatch(getCurrentRequest({ page,
  //     limit,}));
  // }, [dispatch,page,limit]);
  useEffect(() => {
    dispatch(
      getCurrentRequest({
        request_via: "",
        status: "",
      })
    );
  }, [dispatch]);

  const currentData = useSelector((state) => state.currentRequest.currentData);
  console.log("cdata", currentData);
  const total = useSelector((state) => state.currentRequest.total);

  const rows =
    currentData && currentData.length > 0
      ? currentData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category: item.id,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.description,
          submittedDate: item.updated_at,
          status: item.request_status,
          response: item.partner_status,
        }))
      : [];
  return (
    <div>
      <div
        className="px-6 py-2 mb-5 bg-[#FFFFFF]"
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <div
          className="flex justify-between items-center mb-6 py-3"
          style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
        >
          <p className=" py-1 text-[#353F4F] font-medium text-lg mb-3">
            Current Request Status
          </p>

          <div className="flex gap-8 items-center">
            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px]"
                placeholder="Search here..."
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  height: "50px",
                  width: "300px",
                }}
              />
              <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
            <div
              style={{
                background: "rgba(210, 228, 255, 1)",
                color: "rgba(34, 96, 217, 1)",
                borderRadius: "3px",
                width: "100px",
              }}
              onClick={() => navigate("all-request")}
              className="py-3 px-3 text-center cursor-pointer"
            >
              View all
            </div>
          </div>
        </div>
        {/* <DataTable rows={rows} columns={requestColumnn} hideCheckbox hideFooter    pagination
            page={page}
            total={total}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(newLimit) => setLimit(newLimit)}/> */}
        <DataTable
          rows={rows}
          columns={requestColumnn}
          hideCheckbox
          hideFooter
          total={total}
        />
      </div>
    </div>
  );
};

export default CurrentRequest;
