import React, { useEffect, useState } from "react";
import DataTable from "../../shared/DataGrid";
import { TopPartnerColumn } from "../../utills/tableFields";
import { TopAgentRows } from "./adminRows";
import MoreIcon from "../../assets/more-icon.svg";
import { getPartnertList } from "../../services/partnerList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";


// const rows = TopAgentRows.map((row) => ({
//     id: row.id,
//     name: row.name,
//     request: row.request,
//     image: row.image,
//   }));



const TopPartnerList = () => {
  

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  
  };

  useEffect(() => {
    dispatch(getPartnertList());
  }, [dispatch]);

  const partnerData = useSelector((state) => state.partnerData.details);

  const PartnerColumn = [
    ...TopPartnerColumn,
  
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align:"center",
      flex: 1,
      id: 2,
      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
          <div>
            <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
              <img
                src={MoreIcon}
                alt="MoreIcon"
                onClick={(e) => handleClick(e, params.row)}
              />
            </MenuButton>
          </div>
  
          <MenuItems
            transition
            className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <MenuItem
                onClick={() => {
                  navigate(`/partner/${params.id}`);
                }}
              >
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                >
                  View
                </a>
              </MenuItem>
              </div>
              </MenuItems>
              </Menu>
            
        );
      },
    },
  ];

  const rows =
    partnerData && partnerData.length > 0
      ? partnerData.map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          request: item.request_count,
        }))
      : [];
  return (
    <div className="pt-6">
      <DataTable
        rows={rows}
        columns={PartnerColumn}
        hideCheckbox
        hideFooter
        height={450}
      />
    </div>
  );
};

export default TopPartnerList;
