import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";
import Navbar from './Navbar'
import useFindLoginType from './hooks/useFindLoginType';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfo, updateUserType } from './services/user';
import { consumerSite } from './constants/siteConfig';

export default function Layout() {
    const [loading, setLoading] = useState(true)
    const { userType } = useSelector(state => state.userInfo)
    const dispatch = useDispatch()

    useEffect(() => {
        if (loading || userType === '') {
            let userType = ''
            if (consumerSite.includes(window.location.origin)) {
                userType = 'consumer'
                
            } 
           
            else {
                userType = 'other'
            }
                 
            
            localStorage.setItem('loginType', userType)
            dispatch(updateUserType(userType))

            const loggedIn = localStorage.getItem("access_token");
            if (loggedIn) {
                dispatch(updateInfo())
            }
        }
    }, [loading, userType])

    useEffect(() => {
        if (userType !== '') {
            setLoading(false)
        }
    }, [userType])

    if (loading) return <></>


    return (
        <div>
            <Navbar />
            <Outlet />
        </div>
    )
}
