import { createSlice } from "@reduxjs/toolkit";
import { getRequestGraph } from "../../services/requestGraph";

const RequestGraphSlice = createSlice({
  name: "GraphInfo",
  initialState: {
    details:{},
    graphData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequestGraph.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRequestGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getRequestGraph.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default RequestGraphSlice.reducer;
