import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DataTable from '../../shared/DataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPartnerList, submitPartner } from '../../services/partner';
import { PartnerColumn } from '../../utills/tableFields';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import { Button } from '../../shared/Button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AddPartnersInCategories({
  open,
  setOpen,
  id, setId
}) {
  const dispatch = useDispatch();
  const partnerInfo = useSelector((state) => state?.partnerInfo?.partnerData);
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    dispatch(getAllPartnerList());
  }, []);

  const handleClose = () => {
    setOpen("");
  };

  const onChangeHandle = (isChecked, parterId) => {
    if (isChecked) {
      setSelected([...selected, parterId])
    } else {
      const updatedIds = selected.filter((id) => id !== parterId);
      setSelected(updatedIds);
    }
  };

  const PartnersColumn = [
    {
      field: "partners",
      headerName: "select partner",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              onChange={(_, checked) => onChangeHandle(checked, params?.id)}
            />
          </>
        );
      },
    },
    {
      field: "partner_id",
      headerName: "Partner ID",
      flex: 1,
      id: 0,
    },
    {
      field: "full_name",
      headerName: "Organization Name",
      flex: 1,
      id: 1,
    },
    {
      field: "partner_type",
      headerName: "Partner Type",
      flex: 1,
      id: 2,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      id: 1,
    },
    {
      field: "totle_employees",
      headerName: "Total Employees",
      flex: 1,
      id: 1,
    },
  ];

  const rows = Array.isArray(partnerInfo) ? partnerInfo?.map((partner) => ({
    id: partner.id,
    partner_id: partner.id,
    full_name: partner.organization_name,
    partner_type: partner.partner_type,
    category: partner.categories,
    last_update_date: partner.created_at,
    totle_employees: partner.total_org_employee,
    status: partner.status,
  })) : [];

  const onClickOnSubmit = () => {
    const payload = {
      "partners": selected,
      "request_id": id,
      "partner_status": "accepted",
    }
    dispatch(submitPartner(payload))
    setId('')
    handleClose();
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        maxWidth="xl"
      >
        <DialogContent>
          <DataTable className='!w-[800px]' rows={rows} columns={PartnersColumn} hideCheckbox />
          <div className="flex justify-center gap-6 my-10 align-middle">
            <Button
              btnName="Cancel"
              btnCategory="secondary"
              btnCls="w-[200px]"
              onClick={handleClose}
            />
            <Button
              id={"program-submit"}
              btnCls="w-[200px]"
              btnName={"Add to Category"}
              btnCategory="primary"
              onClick={onClickOnSubmit}
            />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}