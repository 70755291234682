import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
export const getRequestAdmin = createAsyncThunk(
    "getRequestAdmin",
    async () => {
        try{
            const response = await api.get("/agent/request-count/");
            if (response.status === 200 && response.data.status === "success"){
                const result = response.data.data;
                return result;
            } else {
                throw new Error("Failed to fetch request count");
              }
            }
            catch (error) {
                console.error("Error fetching request count:", error);
                throw error; 
              }
            
        }
);



