import { createSlice } from "@reduxjs/toolkit";
import {
  getNotification,
  ignoreNotification,
  deleteNotification,
} from "../../services/notification";

const initialState = {
  details: {},
  data: {},
  loading: false,
  status: "",
  error: "",
};

export const notificationSlice = createSlice({
  name: "notificationInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      })
      .addCase(getNotification.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });

    builder
      .addCase(ignoreNotification.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(ignoreNotification.fulfilled, (state, action) => {
        return {
          ...state,
          details: action.payload,
          loading: false,
        };
      })
      .addCase(ignoreNotification.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error.message,
        };
      });
    builder
      .addCase(deleteNotification.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        return {
          ...state,
          details: [],
          loading: false,
        };
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload || "Failed to delete notifications",
        };
      });
  },
});

export default notificationSlice.reducer;
