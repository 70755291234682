import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import CallIcon from '../../../assets/request-phone-icon.svg'
import BreadCrumIcon from '../../../assets/breadcrumb-right-icon.svg'
import { Button } from '../../../shared/Button'
import { getSingleRequest } from '../../../services/currentRequest'
import { useDispatch, useSelector } from 'react-redux'

export default function ViewRequest() {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch();
    const details = useSelector((state) => state.currentRequest.details?.data[0]);

    console.log({ details })

    useEffect(() => {
        if (id) {
            dispatch(getSingleRequest(id))
        }
    }, [dispatch, id])
    
    return (


        <div className="px-8 mt-10">
            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                                onClick={() => navigate('/')}>
                                Dashboard
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">View Request</span>
                            </div>
                        </li>
                    </ol>
                </nav>

            </div>
            <div style={{ border: '1px solid rgba(228, 237, 255, 1)', marginTop: '30px' }} className='px-5 pt-5'>

                <div>
                    <div className='flex justify-between'>
                        <div className='flex items-center justify-center gap-10'>
                            <div className='px-4 py-4 bg-white' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={CallIcon} alt="CallIcon" />
                            </div>
                            <h3 className='text-primary-black text-[24px]'>{details?.first_name} Request</h3>
                            <div style={{ border: '1px solid rgba(255, 205, 17, 1)', borderRadius: '15px' }}
                                className='px-8 py-1 bg-light-yellow text-yellow'>{ details?.request_status}</div>
                        </div>

                        <div className='flex items-center gap-4'>
                            <button style={{ border: '1px solid rgba(220, 53, 69, 1)' }} className='py-3 px-5 w-[200px] text-primary-red'>Cancel Request</button>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/request-tracking')}>Track Request</button>
                        </div>
                    </div>

                    <div className='px-40'>
                        <div className='request-info flex gap-4 text-[12px]'>
                            <p>Request ID : <span className='underline text-primary-blue'>#{details?.id}</span></p>
                            <p>Request Date : <span className='underline text-primary-blue'>{details?.created_at}</span></p>
                        </div>
                    </div>

                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='mt-10 mb-16'>
                        <p className='py-3 pl-6 font-bold bg-light-blue text-primary-blue'>Primary Information</p>
                        <table className='w-full request-view-table'>
                            <tr>
                                <td>First Name</td>
                                <td>{details?.contact_firstname}</td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>{details?.contact_lastname}</td>
                            </tr>
                            <tr>
                                <td>Primary Contact Number</td>
                                <td>{details?.contact}</td>
                            </tr>
                            <tr>
                                <td>Secondary Contact Number</td>
                                <td>{details?.contact_number}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{details?.contact_address}</td>
                            </tr>
                        </table>
                    </div>






                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='mt-10 mb-16'>
                        <p className='py-3 pl-6 font-bold bg-light-blue text-primary-blue'>Request Details</p>
                        <table className='w-full request-view-table'>
                            <tr>
                                <td>Request Via</td>
                                <td>{details?.request_via}</td>
                            </tr>
                            <tr>
                                <td>Request Category</td>
                                <td>{details?.category}</td>
                            </tr>
                            <tr>
                                <td>Number of Beneficiaries</td>
                                <td>{details?.beneficiaries}</td>
                            </tr>
                            <tr>
                                <td>Urgency Level</td>
                                <td>{details?.urgency_level}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>{details?.request_status_desc}</td>
                            </tr>
                        </table>
                    </div>




                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='mt-10 mb-16'>
                        <p className='py-3 pl-6 font-bold bg-light-blue text-primary-blue'>Agent Details</p>
                        <table className='w-full request-view-table'>
                            <tr>
                                <td>Name</td>
                                <td>{details?.agent?.first_name} {details?.agent?.last_name}</td>
                            </tr>
                        </table>
                    </div>




                    <div className="flex justify-center gap-6 my-10 align-middle">
                        <Button btnName='Request Again' btnCategory="secondary" btnCls="w-[200px]" btnStyle={{ border: '1px solid rgba(34, 96, 217, 1)' }} onClick={() => navigate('/')} />
                        <Button btnType="submit" id={'program-submit'} btnCls="w-[200px]" onClick={() => navigate('/request-tracking')}
                            btnName={'Track Request'} btnCategory="primary" />
                    </div>
                </div>
            </div>
        </div>
    )
}
