import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LocationIcon from "../../../src/assets/location-icon.svg";
import BreadCrumIcon from "../../../src/assets/breadcrumb-right-icon.svg";
import { Button } from "../../shared/Button/index.jsx";
import { AddNewPartnerFields } from "../../utills/formFields.js";
import { OrganizationContactPersonFields } from "../../utills/formFields.js";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { Checkbox, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  createNewPartnerRequest,
  getParterDetailsbyId,
  updatePartnerRequest,
} from "../../services/partner";
import { getAllCategoryList } from "../../services/category.js";
import BreadCumbs from "../BreadCumbs/BreadCumbs.jsx";
import { partnerstatus } from "../../utills/formFields.js";
import SuccessDialog from "../SuccessDialog.jsx";

const AddNewPartner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const partnerId = queryParams.get("id");
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [open, setOpen] = useState(false);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((item) => item !== category);
      }
      return [...prev, category];
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
  } = useForm();

  const {
    state,
    loading,
    error: reduxError,
    status,
  } = useSelector((state) => state.partnerInfo);

  const categoryList = useSelector((state) => {
    return state.categoryInfo?.data?.data;
  });

  const partnerInfo = useSelector((state) => {
    return state.partnerInfo?.details;
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (partnerId) {
      dispatch(getParterDetailsbyId(partnerId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(partnerInfo)?.length > 0) {
      reset(partnerInfo);

      if (categoryList?.length > 0) {
        const categoryLookup = Object.fromEntries(
          categoryList?.map((item) => [item.id, item.name])
        );

        const selectedCategoryNames = partnerInfo?.categories?.map(
          (category) => categoryLookup[category] || null
        );
        setSelectedCategories(selectedCategoryNames || []);
      }
    }
  }, [partnerInfo, categoryList]);

  const onSubmit = async (data) => {
    try {
      const categoryLookup = Object.fromEntries(
        categoryList.map((item) => [item.name, item.id])
      );

      const selectedCategoryIds = selectedCategories.map(
        (category) => categoryLookup[category] || null
      );

      if (data.status === "inactive" && !data.reason) {
        console.error("Reason is required when deactivating the agent.");
        return;
      }

      const formData = {
        organization_name: data.organization_name,
        email: data.email,
        contact_number: data.contact_number,
        alternative_number: data.alternative_number,
        total_org_employee: data.total_org_employee,
        org_address: data.org_address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
        status: data.status,
        reason: data.status === "inactive" ? data.reason : "",
        organization: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.emails,
          org_phone_number: data.org_phone_number,
        },
        categories: selectedCategoryIds,
      };

      if (partnerId) {
        const { organization, ...rest } = formData;
        dispatch(updatePartnerRequest({ partner_id: partnerId, ...rest }));
      } else {
        dispatch(createNewPartnerRequest(formData));
      }
      setIsSuccess(true);
      setOpen(true);
      setTimeout(() => {
        navigate("/partners");
      }, 3000);
    } catch (err) {
      setError("An error occurred while submitting the form.");
    }
  };

  // useEffect(() => {
  //   if (isSuccess) {

  //     setTimeout(() => {
  //       navigate("/partners");
  //     }, 3000);
  //   }
  // }, [isSuccess, navigate]);

  const [imageFiles, setImageFiles] = useState([]);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };
  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };
  const payload = {
    status: "active", // Correct value based on API documentation
      
  };
  if (!["active", "inactive", "pending"].includes(payload.status)) {
    throw new Error("Invalid status value.");
  }

  return (
    <>
      <SuccessDialog open={open} onClose={() => setOpen(false)} />
      {loading && <p>Loading...</p>}
      {error && typeof error === "object" ? (
        <p className="error">{error.message || "An error occurred"}</p>
      ) : (
        <p className="error">{error}</p>
      )}
      <div className="dashboard-content px-8 mt-10">
        {partnerId ? (
          <BreadCumbs
            firstCumbName="Partner"
            secoundCumbName="Partner Status"
            thirdCumbName="Partner View (Edit)"
            pathToRedirect="addnewPartner"
            pathToRedirectSecond="partners"
          />
        ) : (
          <BreadCumbs
            firstCumbName="Dashboard"
            secoundCumbName="Add new partner"
            pathToRedirect=""
          />
        )}

        <div
          style={{
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
            marginBottom: "20px",
            borderRadius: "5px",
            border: "1px solid rgba(34, 96, 217, 1)",
          }}
        >
          <div className="px-8 py-y">
            <div
              className="flex items-center justify-between py-6 title"
              style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
            >
              {partnerId ? (
                <div className="flex items-center gap-5">
                  <h1 className="text-[#1D5BBF] font-semibold text-[30px]">
                    ABCD Welfare And Awareness
                  </h1>
                  <div className="border-r border-[#2260D9] h-10" />
                  <div className="flex items-center gap-2 text-[#353F4F]">
                    <span>ID Number : </span>
                    <span>{partnerId ? `#${partnerId}` : ""}</span>
                  </div>
                  <div className="bg-[#F2F7FF] border border-[#2260D9] text-[#2260D9] rounded-[3px] px-[34px] py-[20px] ml-5">
                    EXTERNAL PARTNER
                  </div>
                </div>
              ) : (
                <div className="flex gap-4">
                  <h3 className="text-[20px] text-[#353F4F]">New Partner</h3>
                </div>
              )}

              {partnerId ? (
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="bg-[#2260D9] w-[160px] h-[45px] text-white rounded-[3.9px]"
                >
                  save
                </button>
              ) : null}
            </div>
          </div>

          {partnerId && (
            <div className="flex flex-row w-full items-start p-8 justify-start">
              <div className="flex flex-col w-[20%] space-y-6">
                {partnerstatus.map((field, index) => (
                  <div className={`relative mb-6 ${field.width}`} key={index}>
                    <label
                      className="block mb-2 text-xs font-normal tracking-wide text-gray-700"
                      htmlFor={field.label}
                    >
                      {field.label}{" "}
                      <span style={{ color: "red" }}>
                        {field?.inputRules?.required ? "*" : ""}
                      </span>
                    </label>
                    <div className="py-2 text-yellow-700 bg-yellow-200 rounded">
                      {field.type === "dropdown" ? (
                        <>
                          <Controller
                            name={field.name}
                            control={control}
                            defaultValue={field.options[0]?.value || ""}
                            rules={{
                              validate: (value) =>
                                value === "inactive" && !getValues("reason")
                                  ? "Reason is required when status is 'inactive'."
                                  : true,
                            }}
                            render={({ field: controllerField }) => (
                              <Listbox
                                value={controllerField.value}
                                onChange={(value) =>
                                  controllerField.onChange(value)
                                }
                              >
                                <div className="relative">
                                  <ListboxButton
                                    className={` flex justify-between items-center px-3 input-bg border-none 
                          focus-visible:outline-none text-[14px] h-[60px] text-left bg-white rounded-md ${
                            controllerField.value === "active"
                              ? "text-[#25861D]"
                              : controllerField.value === "inactive"
                              ? "text-[#D22735]"
                              : "text-gray-900"
                          }`}
                                  >
                                    <span className="flex items-center">
                                      {controllerField.value && (
                                        <span
                                          className={`w-3 h-3 rounded-full mr-2 ${
                                            controllerField.value === "active"
                                              ? "bg-[#25861D]"
                                              : controllerField.value ===
                                                "inactive"
                                              ? "bg-[#D22735]"
                                              : "bg-gray-400"
                                          }`}
                                        />
                                      )}
                                      {controllerField.value
                                        ? field.options.find(
                                            (option) =>
                                              option.value ===
                                              controllerField.value
                                          )?.name
                                        : "Select"}
                                    </span>
                                    <ArrowDropDownIcon />
                                  </ListboxButton>
                                  <ListboxOptions className="absolute z-10 mt-1  bg-white rounded-md shadow-lg max-h-60 overflow-auto focus:outline-none">
                                    {field.options.map((option, idx) => (
                                      <ListboxOption
                                        key={idx}
                                        value={option.value}
                                        className={({ active }) =>
                                          `cursor-pointer relative py-2 px-4 flex items-center ${
                                            active
                                              ? "bg-blue-600"
                                              : option.value === "active"
                                              ? "text-[#25861D]"
                                              : option.value === "inactive"
                                              ? "text-[#D22735]"
                                              : "text-gray-900"
                                          }`
                                        }
                                      >
                                        <span
                                          className={`w-3 h-3 rounded-full mr-2 ${
                                            option.value === "active"
                                              ? "bg-[#25861D]"
                                              : option.value === "inactive"
                                              ? "bg-[#D22735]"
                                              : "bg-gray-400"
                                          }`}
                                        />
                                        {option.name}
                                      </ListboxOption>
                                    ))}
                                  </ListboxOptions>
                                </div>
                              </Listbox>
                            )}
                          />
                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col w-full md:w-1/2 gap-3.5">
                {watch("status") === "inactive" && (
                  <>
                    <label
                      htmlFor="Comment"
                      className="text-[#353F4F] text-[12px] flex items-center"
                    >
                      Reason{" "}
                      <span className=" ml-1" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <textarea
                      id="reason"
                      name="reason"
                      rows={6}
                      {...register("reason", {
                        required:
                          "Reason is required when deactivating the Partner.",
                      })}
                      className="block w-full rounded-md h-[60px] bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
                      placeholder="Enter your reason for changing the status"
                    />
                    {errors.reason && (
                      <p className="error" role="alert">
                        {errors.reason.message}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8 py-4">
              {loading && <p>Loading...</p>}
              {error && <p className="error">{error}</p>}
              {isSuccess && !loading && !error && (
                <div className="success-message">
                  <p>Partner successfully added!</p>
                </div>
              )}
              <div
                className="flex  py-5 mb-8 items-center justify-between"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Partner Primary Information
                </div>
                <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                  <ArrowDropDownIcon />
                </div>
              </div>

              <div className="flex flex-wrap gap-4">
                {AddNewPartnerFields.map((field, index) => {
                  return (
                    <div
                      className={`relative mb-6  ${field.width}`}
                      key={index}
                    >
                      <label
                        className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                        htmlFor={field.label}
                      >
                        {field.label}{" "}
                        <span style={{ color: "red" }}>
                          {field?.inputRules?.required ? "*" : ""}
                        </span>
                      </label>
                      {field.type === "input" ? (
                        <div className="relative">
                          <input
                            {...register(field.name, field.inputRules)}
                            type={field.fieldType}
                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                            placeholder={field.placeholder}
                            style={{
                              color: "#232323",
                              borderRadius: "3px",
                            }}
                            aria-invalid={!!errors[field.name]}
                          />
                          {field.icon && field.icon === "location" && (
                            <img
                              className="absolute top-4 right-4"
                              src={LocationIcon}
                              alt="LocationIcon"
                            />
                          )}

                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              <div
                className="flex  py-5 mb-8 items-center justify-between"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Organization Contact Person
                </div>
                <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                  <ArrowDropDownIcon />
                </div>
              </div>
              <div className="flex flex-wrap gap-4">
                {OrganizationContactPersonFields.map((field, index) => {
                  return (
                    <div
                      className={`relative mb-6  ${field.width}`}
                      key={index}
                    >
                      <label
                        className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                        htmlFor={field.label}
                      >
                        {field.label}{" "}
                        <span style={{ color: "red" }}>
                          {field?.inputRules?.required ? "*" : ""}
                        </span>
                      </label>
                      {field.type === "input" ? (
                        <div className="relative">
                          <input
                            {...register(field.name, field.inputRules)}
                            type={field.fieldType}
                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                            placeholder={field.placeholder}
                            style={{
                              color: "#232323",
                              borderRadius: "3px",
                            }}
                            aria-invalid={!!errors[field.name]}
                          />
                          {field.icon && field.icon === "location" && (
                            <img
                              className="absolute top-4 right-4"
                              src={LocationIcon}
                              alt="LocationIcon"
                            />
                          )}

                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div className="w-full">
                <div
                  className="flex  py-5 mb-8 items-center justify-between"
                  style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                >
                  <div className="text-primary-blue text-[18px]">
                    Category Assignment
                  </div>
                  <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                    <ArrowDropDownIcon />
                  </div>
                </div>
                <label
                  className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2"
                  htmlFor="categories"
                >
                  Categories{" "}
                  <span style={{ color: "red" }}>{true ? "*" : ""}</span>
                </label>
                {/* {true && (
                <p className="text-[#FF0000] text-md">This field is required</p>
              )} */}
                <div>
                  <Listbox
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                  >
                    {({ open }) => (
                      <div className="relative mt-2">
                        <ListboxButton className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                          <span className="flex items-center">
                            {selectedCategories?.length === 0 && (
                              <InputLabel
                                className="text-gray-500 "
                                shrink={false}
                              >
                                Select a Category
                              </InputLabel>
                            )}
                            <Stack direction="row" spacing={1}>
                              {selectedCategories?.map((category, index) => (
                                <Chip
                                  key={index}
                                  label={category}
                                  onDelete={() => {
                                    setSelectedCategories((prev) =>
                                      prev.filter((item) => item !== category)
                                    );
                                  }}
                                  sx={{
                                    backgroundColor: "#F0F0F0",
                                    color: "#2260D9",
                                    ".MuiChip-deleteIcon": {
                                      color: "#FF0000",
                                    },
                                  }}
                                />
                              ))}
                            </Stack>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            {open ? (
                              <ArrowDropUpIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                              />
                            ) : (
                              <ArrowDropDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                              />
                            )}
                          </span>
                        </ListboxButton>

                        <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {(categoryList?.map((e) => e.name) || [])?.map(
                            (category) => (
                              <ListboxOption
                                key={category}
                                value={category}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 "
                              >
                                <div className="flex items-center">
                                  <Checkbox
                                    checked={
                                      selectedCategories.indexOf(category) > -1
                                    }
                                  />
                                  <span className="ml-3 block truncate">
                                    {category}
                                  </span>
                                </div>
                              </ListboxOption>
                            )
                          )}
                        </ListboxOptions>
                      </div>
                    )}
                  </Listbox>
                </div>
              </div>

              <div className="w-full">
                <div
                  className="flex  py-5 mb-8 items-center justify-between"
                  style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                >
                  <div className="text-primary-blue text-[18px]">
                    Additional Informations
                  </div>
                  <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                    <ArrowDropDownIcon />
                  </div>
                </div>
                <div className="w-full">
                  <label className="block tracking-wide text-[#353F4F] text-xs font-normal mb-2">
                    Category Logo (Optional)
                  </label>
                  <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                    <Stack direction="row" spacing={1}>
                      {imageFiles.map((file, index) => (
                        <Chip
                          key={index}
                          label={file.name}
                          onDelete={() => handleDelete(file)}
                          sx={{
                            backgroundColor: "#F0F0F0",
                            color: "#2260D9",
                            ".MuiChip-deleteIcon": {
                              color: "#FF0000",
                            },
                          }}
                        />
                      ))}
                    </Stack>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                      id="image-upload"
                      style={{
                        color: "#232323",
                        borderRadius: "3px",
                        display: "none",
                      }}
                      onChange={handleImageChange}
                    />
                    <button
                      className="bg-[#2260D9] text-white rounded-[2px] p-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        document.getElementById("image-upload").click();
                      }}
                    >
                      <AddIcon className="" />
                      Upload Image
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex gap-6 my-10 justify-center align-middle">
                <Button
                  btnName="Cancel"
                  btnCategory="secondary"
                  btnCls="w-[200px]"
                  onClick={() => navigate("/partners")}
                />
                <Button
                  btnType="submit"
                  id={"program-submit"}
                  btnCls="w-[200px]"
                  btnName={"Submit"}
                  btnCategory="primary"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewPartner;
