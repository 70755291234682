import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const getPartnerRequestById = createAsyncThunk(
  "getPartnerRequestById",
  async (id) => {
    try {
      const response = await api.get(
        `/agent/partner-request-stats/?partner_id=${id}`
      );

      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error("Failed to fetch data");
    } catch (error) {
      throw error.response?.data?.message || error.message;
    }
  }
);

export const getPartnerRequestByStatus = createAsyncThunk(
  "getPartnerRequestByStatus",
  async ({ id, status }) => {
    try {
      const response = await api.get(
        `/agent/?status=${status}&partner_id=${id}`
      );

      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error("Failed to fetch data");
    } catch (error) {
      throw error.response?.data?.message || error.message;
    }
  }
);
