import { useState, useEffect } from "react";
import SearchIcon from "../../assets/search-icon.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { getAllPartnerRequestList } from "../../services/request";
import { useDispatch, useSelector } from "react-redux";
import OptIcon from "../../assets/opt.svg";
import ViaIcon from "../../assets/via.svg";
import moment from "moment";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";

export const ResponseCell = ({ value }) => {
  const [selectedStatus, setSelectedStatus] = useState(value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const colors = {
    "in progress": "text-[#FFCD11]",
    accepted: "text-[#278E1D]",
    wait: "text-[#1D5BBF]",
    "on hold": "text-[#E26313]",
    rejected: "text-[#DC3545]",
    completed: "text-[#278E1D]",
  };

  const statusOptions = [
    "in progress",
    "accepted",
    "wait",
    "on hold",
    "rejected",
    "completed",
  ];

  const filteredOptions = statusOptions.filter(
    (option) => option !== selectedStatus
  );

  return (
    <div className="">
      <Listbox
        value={selectedStatus}
        onChange={setSelectedStatus}
        className="flex flex-col absolute"
      >
        {({ open }) => (
          <>
            <ListboxButton
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className={`capitalize ${
                colors[selectedStatus] || ""
              }  border-none rounded   w-full cursor-pointer flex items-center justify-between`}
            >
              {open ? (
                <p className="text-[#848484]">Select Status</p>
              ) : (
                selectedStatus
              )}

              {open ? (
                <KeyboardArrowUpIcon className="ml-2 text-[#353F4F]" />
              ) : (
                <KeyboardArrowDownIcon className="ml-2 text-[#353F4F]" />
              )}
            </ListboxButton>
            {open && (
              <ListboxOptions className="absolute w-40  z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-b-md bg-white border border-[#D9D9D9] shadow-lg  transition focus:outline-none ">
                {filteredOptions.map((option) => (
                  <ListboxOption
                    key={option}
                    value={option}
                    className={`block px-4 py-2 text-sm capitalize ${
                      colors[option] || ""
                    } border-[#D9D9D9]`}
                  >
                    {option}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

function Grid() {
  const [anchorEl, setAnchorEl] = useState(null);

  const userData = useSelector((state) => state.userInfo.data);
  const id = userData.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };
  const currentRequestData = useSelector(
    (state) => state.requestInfo?.data?.result?.data
  );
  useEffect(() => {
    if (id) dispatch(getAllPartnerRequestList(id));
  }, [id]);

  const columns = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
    },
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "requestedBy",
      headerName: "Requested By",
      flex: 1,
      rendercell: (params) => <div className="capitalize">{params.value}</div>,
    },
    {
      field: "receivedDate",
      headerName: "Received Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },

    {
      field: "response",
      headerName: "Your Response",
      flex: 2,
      renderCell: (params) => (
        <div>
          <ResponseCell value={params.value} />
        </div>
      ),
    },

    {
      field: "requestStatus",
      headerName: "Request Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]",
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Menu as="div" className="inline-block text-left mt-2">
            <div>
              <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                <img
                  src={MoreIcon}
                  alt="MoreIcon"
                  onClick={(e) => handleClick(e, params.row)}
                />
              </MenuButton>
            </div>
            <MenuItems
              transition
              className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem
                  onClick={() => {
                    navigate(`/request-tracking`);
                  }}
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                  >
                    View
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        );
      },
    },
  ];

  const rows =
    currentRequestData && currentRequestData.length > 0
      ? currentRequestData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category: item.id,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.description,
          receivedDate: item.updated_at,
          requestStatus: item.request_status,
          response: item.partner_status,
        }))
      : [];

  return (
    <div className="mt-10 p-2 custom-shadow rounded">
      <div className="p-4 flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Requests</h2>
        <div className="flex gap-8">
          <div className="relative">
            <input
              type="text"
              className="block w-full p-2 text-sm text-gray-900 outline-none rounded-[3px] h-[58px] "
              placeholder="Search here..."
              style={{
                border: "1px solid rgba(29, 91, 191, 1)",
                width: "350px",
              }}
              onChange={(e) => console.log(e)}
            />
            <div className="absolute right-2 top-3">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
          <button className="bg-[#D9E4F2] text-[#1D5BBF] px-8 py-4 rounded">
            View All
          </button>
        </div>
      </div>
      <div className="border-t-2 border-t-gray-bg my-2"></div>
      <div className="p-4">
        <DataGrid columns={columns} rows={rows} />
      </div>
    </div>
  );
}

export default Grid;
