import { createSlice } from "@reduxjs/toolkit";
import { getUserType } from "../../services/userType";
import { profileDetails } from "../../services/userType";

const userTypeSlice = createSlice({
  name: "userTypeData",
  initialState: {
    details:{},
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserType.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        
        
      })
      .addCase(getUserType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


      builder
      .addCase(profileDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(profileDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(profileDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userTypeSlice.reducer;
