import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import LocationIcon from "../../../src/assets/location-icon.svg";
import { Button } from "../../shared/Button/index.jsx";
import {
  AddNewAgentFields,
  OrgannizationAgentFields,
} from "../../utills/formFields.js";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { createNewAgent, getAgentById } from "../../services/agent";
import { useLocation } from "react-router";
import { updateAgent } from "../../services/agent";
import { editstatus } from "../../utills/formFields.js";
import BreadCumbs from "../BreadCumbs/BreadCumbs.jsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import SuccessDialog from "../SuccessDialog.jsx";

const AddNewAgent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [imageFiles, setImageFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const agentId = queryParams.get("id");
  const agentInfo = useSelector((state) => state.agentInfo.agentDetails);

  useEffect(() => {
    if (agentId) {
      dispatch(getAgentById(agentId));
    }
  }, [agentId]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
  } = useForm();

  const { state, loading, error, status } = useSelector(
    (state) => state.agentInfo
  );

  useEffect(() => {
    if (status === "Agent Created Successfully") {
      setOpen(true);
      setTimeout(() => {
        navigate("/all-agent");
        setOpen(false);
      }, 2000);
    } else if (status === "error") {
      console.error("Error:", error);
    }
    if (agentInfo) {
      console.log(agentInfo);
    }
  }, [status, error, navigate, agentInfo]);

  const fetchAgentDetails = useCallback(() => {
    if (agentInfo) {
      reset({
        first_name: agentInfo.first_name,
        last_name: agentInfo.last_name,
        email: agentInfo.email,
        contact_number: agentInfo.contact_number,
        alternative_number: agentInfo.alternative_number,
        address: agentInfo.address,
        city: agentInfo.city,
        state: agentInfo.state,
        postal_code: agentInfo.postal_code,
        country: agentInfo.country,
        status: agentInfo.status,
        reason: agentInfo.status === "inactive" ? agentInfo.reason : "",
        organization: {
          name: agentInfo.organization?.name,
          email: agentInfo.organization?.email,
          org_phone_number: agentInfo.organization?.org_phone_number,
        },
      });
    }
  }, [agentId, agentInfo, reset]);

  const onSubmit = (data) => {
    if (data.status === "inactive" && !data.reason) {
      console.error("Reason is required when deactivating the agent.");
      return;
    }

    const formattedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      contact_number: data.contact_number,
      alternative_number: data.alternative_number,
      address: data.address,
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      country: data.country,
      status: data.status,
      reason: data.status === "inactive" ? data.reason : "",
      organization: {
        name: data.organization.name,
        email: data.organization.email,
        org_phone_number: data.organization.org_phone_number,
      },
    };

    if (agentId) {
      // Edit mode
      const { organization, ...rest } = formattedData;
      dispatch(updateAgent({ agent_id: agentId, data: rest }))
        .then(() => {
          dispatch(getAgentById(agentId));
          setOpen(true);
          setTimeout(() => {
            navigate("/all-agent");
            setOpen(false);
          }, 2000);
        })
        .catch((err) => console.error("Error updating agent:", err));
    } else {
      // Create mode
      dispatch(createNewAgent(formattedData))
        .then(() => {
          setOpen(true);
          setTimeout(() => {
            navigate("/all-agent");
            setOpen(false);
          }, 2000);
        })
        .catch((err) => console.error("Error creating agent:", err));
    }
  };

  useEffect(() => {
    if (status === "Agent Created Successfully") {
      navigate("/allagent");
    } else if (status === "error") {
      console.error("Error:", error);
    }
    if (agentInfo) {
      console.log(agentInfo);
      fetchAgentDetails();
    }
  }, [status, error, navigate, agentInfo, fetchAgentDetails]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...imageFiles, ...files];
    setImageFiles(updatedFiles);
  };
  const handleDelete = (fileToDelete) => {
    setImageFiles(imageFiles.filter((file) => file !== fileToDelete));
  };

  return (
    <>
      <SuccessDialog open={open} onClose={() => setOpen(false)} />
      {loading && <p>Loading...</p>}
      {error && typeof error === "object" ? (
        <p className="error">{error.message || "An error occurred"}</p>
      ) : (
        <p className="error">{error}</p>
      )}
      <div className="px-8 mt-10">
        {agentId ? (
          <BreadCumbs
            firstCumbName="Agent"
            secoundCumbName="Agent Status"
            thirdCumbName="Agent View (Edit)"
            pathToRedirect="addnewagent"
            pathToRedirectSecond="all-agent"
          />
        ) : (
          <BreadCumbs
            firstCumbName="Dashboard"
            secoundCumbName="Add Agent"
            pathToRedirect=""
          />
        )}

        <div
          style={{
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.05)",
            marginBottom: "20px",
            borderRadius: "5px",
            border: "1px solid rgba(34, 96, 217, 1)",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8 py-y">
              <div
                className="flex items-center justify-between py-6 title"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                {agentId ? (
                  ""
                ) : (
                  <div className="flex gap-4">
                    <h3 className="text-[20px] text-[#353F4F]">New Agent</h3>
                  </div>
                )}

                <div className="flex items-center gap-2">
                  <span>Agent ID : </span>
                  <span>{agentId ? `#${agentId}` : ""}</span>
                </div>
                {agentId ? (
                  <button
                    type="button"
                    className="bg-[#2260D9] w-[160px] h-[45px] text-white rounded-[3.9px]"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </button>
                ) : null}
              </div>
            </div>

            {agentId && (
              <div className="flex flex-row w-full items-start p-8 justify-start">
                <div className="flex flex-col w-[20%] space-y-6">
                  {editstatus.map((field, index) => (
                    <div className={`relative mb-6 ${field.width}`} key={index}>
                      <label
                        className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                        htmlFor={field.label}
                      >
                        {field.label}{" "}
                        <span style={{ color: "red" }}>
                          {field?.inputRules?.required ? "*" : ""}
                        </span>
                      </label>
                      <div className="py-2 text-yellow-700 bg-yellow-200 rounded">
                        {field.type === "dropdown" ? (
                          <>
                            <Controller
                              name={field.name}
                              control={control}
                              defaultValue={field.options[0]?.value || ""}
                              rules={{
                                validate: (value) =>
                                  value === "inactive" && !getValues("reason")
                                    ? "Reason is required when status is 'inactive'."
                                    : true,
                              }}
                              render={({ field: controllerField }) => (
                                <Listbox
                                  value={controllerField.value}
                                  onChange={(value) =>
                                    controllerField.onChange(value)
                                  }
                                >
                                  <div className="relative">
                                    <ListboxButton
                                      className={` flex justify-between items-center px-3 input-bg border-none 
                          focus-visible:outline-none text-[14px] h-[60px] text-left bg-white rounded-md ${
                            controllerField.value === "active"
                              ? "text-[#25861D]"
                              : controllerField.value === "inactive"
                              ? "text-[#D22735]"
                              : "text-gray-900"
                          }`}
                                    >
                                      <span className="flex items-center">
                                        {controllerField.value && (
                                          <span
                                            className={`w-3 h-3 rounded-full mr-2 ${
                                              controllerField.value === "active"
                                                ? "bg-[#25861D]"
                                                : controllerField.value ===
                                                  "inactive"
                                                ? "bg-[#D22735]"
                                                : "bg-gray-400"
                                            }`}
                                          />
                                        )}
                                        {controllerField.value
                                          ? field.options.find(
                                              (option) =>
                                                option.value ===
                                                controllerField.value
                                            )?.name
                                          : "Select"}
                                      </span>
                                      <ArrowDropDownIcon />
                                    </ListboxButton>
                                    <ListboxOptions className="absolute z-10 mt-1  bg-white rounded-md shadow-lg max-h-60 overflow-auto focus:outline-none">
                                      {field.options.map((option, idx) => (
                                        <ListboxOption
                                          key={idx}
                                          value={option.value}
                                          className={({ active }) =>
                                            `cursor-pointer relative py-2 px-4 flex items-center ${
                                              active
                                                ? "bg-blue-600"
                                                : option.value === "active"
                                                ? "text-[#25861D]"
                                                : option.value === "inactive"
                                                ? "text-[#D22735]"
                                                : "text-gray-900"
                                            }`
                                          }
                                        >
                                          <span
                                            className={`w-3 h-3 rounded-full mr-2 ${
                                              option.value === "active"
                                                ? "bg-[#25861D]"
                                                : option.value === "inactive"
                                                ? "bg-[#D22735]"
                                                : "bg-gray-400"
                                            }`}
                                          />
                                          {option.name}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </div>
                                </Listbox>
                              )}
                            />
                            {errors[field.name] && (
                              <p className="error" role="alert">
                                {errors[field.name].message}
                              </p>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-full md:w-1/2 gap-3.5">
                  {watch("status") === "inactive" && (
                    <>
                      <label
                        htmlFor="Comment"
                        className="text-[#353F4F] text-[12px] flex items-center"
                      >
                        Reason{" "}
                        <span className=" ml-1" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <textarea
                        id="reason"
                        name="reason"
                        rows={6}
                        {...register("reason", {
                          required:
                            "Reason is required when deactivating the agent.",
                        })}
                        className="block w-full rounded-md h-[60px] bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
                        placeholder="Enter your reason for changing the status"
                      />
                      {errors.reason && (
                        <p className="error" role="alert">
                          {errors.reason.message}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="px-8 py-4">
              <div
                className="flex  py-5 mb-8 items-center justify-between"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Agent Primary Information
                </div>
                <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                  <ArrowDropDownIcon />
                </div>
              </div>
              <div className="flex flex-wrap gap-4">
                {AddNewAgentFields.map((field, index) => {
                  return (
                    <div
                      className={`relative mb-6  ${field.width}`}
                      key={index}
                    >
                      <label
                        className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                        htmlFor={field.label}
                      >
                        {field.label}{" "}
                        <span style={{ color: "red" }}>
                          {field?.inputRules?.required ? "*" : ""}
                        </span>
                      </label>
                      {field.type === "input" ? (
                        <div className="relative">
                          <input
                            {...register(field.name, field.inputRules)}
                            // value={formData?.[field.name] || ""}
                            type={field.fieldType}
                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:outline-none text-[14px] h-[60px]"
                            placeholder={field.placeholder}
                            style={{ color: "#232323", borderRadius: "3px" }}
                          />
                          {field.icon && field.icon === "location" && (
                            <img
                              className="absolute top-4 right-4"
                              src={LocationIcon}
                              alt="LocationIcon"
                            />
                          )}

                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              <div
                className="flex  py-5 mb-8 items-center justify-between"
                style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
              >
                <div className="text-primary-blue text-[18px]">
                  Organization Contact Person (if any)
                </div>
                <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                  <ArrowDropDownIcon />
                </div>
              </div>

              <div className="flex flex-wrap gap-4">
                {OrgannizationAgentFields.map((field, index) => {
                  return (
                    <div
                      className={`relative mb-6  ${field.width}`}
                      key={index}
                    >
                      <label
                        className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]"
                        htmlFor={field.label}
                      >
                        {field.label}{" "}
                        <span style={{ color: "red" }}>
                          {field?.inputRules?.required ? "*" : ""}
                        </span>
                      </label>
                      {field.type === "input" ? (
                        <div className="relative">
                          <input
                            {...register(field.name, field.inputRules)}
                            type={field.fieldType}
                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                            placeholder={field.placeholder}
                            style={{
                              color: "#232323",
                              borderRadius: "3px",
                            }}
                            aria-invalid={!!errors[field.name]}
                          />
                          {field.icon && field.icon === "location" && (
                            <img
                              className="absolute top-4 right-4"
                              src={LocationIcon}
                              alt="LocationIcon"
                            />
                          )}

                          {errors[field.name] && (
                            <p className="error" role="alert">
                              {errors[field.name].message}
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>

              <div className="w-full">
                <div
                  className="flex  py-5 mb-8 items-center justify-between"
                  style={{ borderBottom: "2px solid rgba(211, 211, 211, 1)" }}
                >
                  <div className="text-primary-blue text-[18px]">
                    Additional Informations
                  </div>
                  <div className="bg-[#F1F6FF] w-6 h-6 rounded-full text-center">
                    <ArrowDropDownIcon />
                  </div>
                </div>

                <div className="w-full">
                  <label className="block mb-2 text-xs font-normal tracking-wide text-[#353F4F]">
                    Documents (Optional)
                  </label>
                  <div className="relative flex justify-between items-center w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]">
                    <Stack direction="row" spacing={1}>
                      {imageFiles.map((file, index) => (
                        <Chip
                          key={index}
                          label={file.name}
                          onDelete={() => handleDelete(file)}
                          sx={{
                            backgroundColor: "#F0F0F0",
                            color: "#2260D9",
                            ".MuiChip-deleteIcon": {
                              color: "#FF0000",
                            },
                          }}
                        />
                      ))}
                    </Stack>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      multiple
                      className={`bg-transparent w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px]`}
                      id="image-upload"
                      style={{
                        color: "#232323",
                        borderRadius: "3px",
                        display: "none",
                      }}
                      onChange={handleImageChange}
                    />
                    <button
                      className="bg-[#2260D9] text-white rounded-[2px] p-2"
                      onClick={(e) =>{
                        e.preventDefault();
                        e.stopPropagation();
                        document.getElementById("image-upload").click()
                      }}
                    >
                      <AddIcon className="" />
                      Upload Image
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center gap-6 my-10 align-middle">
                <Button
                  btnName="Cancel"
                  btnCategory="secondary"
                  btnCls="w-[200px]"
                  onClick={() => navigate("/allagent")}a
                />
                <Button
                  btnType="submit"
                  id={"program-submit"}
                  btnCls="w-[200px]"
                  btnName={"Submit"}
                  btnCategory="primary"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewAgent;
