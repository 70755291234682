import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

// Create Request
export const createNewPartnerRequest = createAsyncThunk(
  "createNewRequest",
  async (formData, { rejectWithValue }) => {
    try {
      const createRequest = await api.post(`/agent/partner/`, formData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getAllPartnerList = createAsyncThunk(
  "getAllPartnerList",
  async (status) => {
    const getAllPartnerInfo = await api.get(`/agent/partner/${status ? `?partner_status=${status}` : ''}`);
    if (getAllPartnerInfo.status === 200 && getAllPartnerInfo.data) {
      const result = getAllPartnerInfo.data.result;
      return result;
    }
    return getAllPartnerInfo;
  }
);

export const getPartnerList = createAsyncThunk(
  "getPartnerList",
  async (status) => {
    const getAllPartnerInfo = await api.get(`/agent/partner/${status ? `?partner_status=${status}` : ''}`);
    if (getAllPartnerInfo.status === 200 && getAllPartnerInfo.data) {
      const result = getAllPartnerInfo.data.result;
      return result;
    }
    return getAllPartnerInfo;
  }
);

export const updatePartnerStatus = ({ status, partner_id, reason }) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_PARTNER_REQUEST" });
    const response = await api.put(`/agent/partner/`, { status, partner_id, reason });
    dispatch({ type: "UPDATE_PARTNER_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_PARTNER_FAILURE", payload: error.message });
  }
};

export const getTotalEmployeesColumnList = createAsyncThunk(
  "getTotalEmployeesColumnList",
  async () => {
    const getTotalEmployeesColumnInfo = await api.get("/agent/employee/");

    if (
      getTotalEmployeesColumnInfo.status === 200 &&
      getTotalEmployeesColumnInfo.data
    ) {
      const result = getTotalEmployeesColumnInfo.data.result;
      return result;
    }
    return getTotalEmployeesColumnInfo;
  }
);

export const getParterDetailsbyId = createAsyncThunk(
  "getParterDetailsbyId",
  async (id) => {
    const getParterDetailsInfo = await api.get(`/agent/partner/?partner_id=${id}`);
    if (getParterDetailsInfo.status === 200 && getParterDetailsInfo.data) {
      const result = getParterDetailsInfo.data.result;
      return result;
    }
    return getParterDetailsInfo;
  }
);


// Update Request
export const updatePartnerRequest = createAsyncThunk(
  "updatePartner",
  async (formData, { rejectWithValue }) => {
    try {
      const createRequest = await api.put(`/agent/partner/`, formData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const submitPartner = createAsyncThunk(
  "submitPartner",
  async (formData, { rejectWithValue }) => {
    try {
      const createRequest = await api.put(`/agent/?request_id=${formData.request_id}`, formData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create agent");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);