import React, { useState, useEffect } from "react";
import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import { ExternalCategoryTab } from "../../utills/formFields";
import SearchIcon from "../../assets/input-search-icon.svg";
import DataTable from "../../shared/DataGrid";
import { ExternalCategoryColumn } from "../../utills/tableFields";
import MoreIcon from "../../assets/more-icon.svg";
import { useNavigate } from "react-router";
import { getCategoryById } from "../../services/category";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Breadcumbs from "../BreadCumbs/BreadCumbs";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import moment from "moment";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import ViaIcon from "../../assets/via.svg";
import OptIcon from "../../assets/opt.svg";


const ExternalCategory = () => {
  const { id } = useParams();
  const [actionTab, setActiveTab] = useState("totalpartners");

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoryById(id));
  }, [id, dispatch]);

  const categoryData = useSelector((state) => state.categoryInfo.details);
  // console.log("categoryData", categoryData);

  const requestData = useSelector(
    (state) => state.categoryInfo.details.request
  );

  const partnerData = useSelector(
    (state) => state.categoryInfo.details.partners
  );

  const CurrentStatusColumn = [
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
    },
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
  
    {
      field: "via",
      headerName: "Via",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <p className="font-sans text-[#18283D] font-semibold">Via</p>
          <img src={ViaIcon} alt="via" />
        </div>
      ),
      renderCell: (params) => (
        <div className="capitalize">{params.value}</div>
      )
    },
    {
      field: "intakeDate",
      headerName: "Intake Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      renderCell: (params) => (
        <div className="capitalize">{params.value}</div>
      )
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "submittedDate",
      headerName: "Submitted Date & Time",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },  
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          completed: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]"
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    

    {
      field: "partner",
      headerName: "Partner",
      flex: 1,
    },
    
    {
      field: "response",
      headerName: "Partner Response",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const colors = {
          "in progress": "text-[#FFCD11]",
          accepted: "text-[#278E1D]",
          wait: "text-[#1D5BBF]",
          "on hold": "text-[#E26313]",
          rejected: "text-[#DC3545]",
          new: "text-[#1D5BBF]," 
        };
        return (
          <div className={`capitalize ${colors[params.value] || ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      renderCell: () => {
        return (
          <div className="cursor-pointer flex items-center justify-center h-full">
            <img src={MoreIcon} alt="More Icon" />
          </div>
        );
      },
    },
  ];

  const topCategoryRows =
    requestData && requestData.length > 0
      ? requestData.map((item) => ({
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.request_status_desc,
          submittedDate: item.updated_at,
          status: item.request_status,
          response: item.partner_status,
        }))
      : [];

  console.log("rows", topCategoryRows);

  const topPartnersRows =
    partnerData && partnerData.length > 0
      ? partnerData.map((item) => ({
          id: item.id,
          partnerId: item.id,
          name: item.organization_name,
          manageCategory: item.categories.length,
          lastUpdateDate: item.updated_at,
          status: item.status,
        }))
      : [];

  const TopPartnerColumns = [
    ...ExternalCategoryColumn,
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date & Time",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },
   
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: "text-[#29B473]" ,
          inactive: "text-[#D22735]",
        };
        const status = params.row.status;
        return (
          <div
            className={`flex items-center justify-center gap-2 ${statusStyles[params.value]}`}
          >
            {status === "active" ? (
              <img src={RightIcon} />
            ) : (
              <img src={BlockIcon} />
            )}
            <span className="capitalize">
              {status}
            </span>
          </div>
        );
      },
    }, 
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return (
          <>
            <Menu as="div" className="inline-block text-left mt-2">
              <div>
                <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                  <img
                    src={MoreIcon}
                    alt="MoreIcon"
                    onClick={(e) => handleClick(e, params.row)}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/partner/${params.id}`);
                    }}
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate(`/addnewpartner?id=${params.id}`)}
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Edit
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Deactivate
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];

  const onAddParterClick = () => navigate("/addnewpartner");

  return (
    <div className="px-8 mt-10">
      <div className="mb-6">
        <Breadcumbs
          firstCumbName="category"
          secoundCumbName="Category Status"
          thirdCumbName="Category View"
          pathToRedirect="addnewcategory"
          pathToRedirectSecond="allcategory"
        />
      </div>
      <div className=" px-6 py-10 mb-5 border border-[#E4EDFF] rounded-[5px]">
        <div className="border border-[#2260D9] bg-[#F2F7FF] rounded-[3px] text-[#2260D9] font-semibold text-2xl px-5 py-2 w-fit">
          {categoryData.category_type} Category
        </div>
        <div className="flex items-center pt-14 pb-6 border-b border-[#E4E6EF]">
          <p className="text-[#1D5BBF] font-semibold text-3xl border-r border-[#2260D9] pr-7 py-1 uppercase">
            {categoryData.name}
          </p>
          <p className="text-[#353F4F] text-xl font-medium pl-7 ">
            Category Id: {categoryData.id}
          </p>
          <p
            className={`text-base font-medium px-5 py-1 ml-7 rounded-full capitalize ${
              categoryData.status === "inactive"
                ? "bg-[#FFEDED] border border-[#FF4D4F] text-[#FF4D4F]"
                : "bg-[#EDFFF4] border border-[#35A462] text-[#35A462]"
            }`}
          >
            {categoryData.status}
          </p>
        </div>
        <div className="py-10 mb-6 text-[#353F4F] text-sm">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop.
        </div>
        <div className="details-info mb-8">
          <div>
            <ul className="tab-list border-b border-[#E4E6EF]">
              {ExternalCategoryTab.map((discussion, index) => (
                <li
                  className={`${
                    actionTab === discussion.key ? "active" : ""
                  } relative `}
                  style={{ width: "150px" }}
                  key={index}
                  onClick={() => setActiveTab(discussion.key)}
                >
                  <div className="text-[13px]"> {`${discussion.name}`}</div>
                  {actionTab === discussion.key && <span></span>}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-end items-center my-10">
            <div className="flex items-stretch gap-7">
              <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px]"
                  placeholder="Search here..."
                  style={{
                    border: "1px solid rgba(34, 96, 217, 1)",
                    height: "50px",
                    width: "300px",
                  }}
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
              <button
                className="bg-[#2260D9] font-medium text-white text-base rounded-[3.9px] px-12"
                onClick={onAddParterClick}
              >
                Add Partners
              </button>
            </div>
          </div>
          {actionTab === "totalpartners" && (
            <div>
              <DataTable
                rows={topPartnersRows}
                columns={TopPartnerColumns}
                hideCheckbox
                hideFooter
              />
            </div>
          )}
          {actionTab === "totalrequest" && (
            <div>
              <DataTable
                rows={topCategoryRows}
                columns={CurrentStatusColumn}
                hideCheckbox
                hideFooter
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExternalCategory;
