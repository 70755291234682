import { createSlice } from "@reduxjs/toolkit";
import { createCurrentRequest, getCurrentRequest, getSingleRequest } from "../../services/currentRequest";

const currentRequestSlice = createSlice({
  name: "currentRequest",
  initialState: {
    details: {},
    currentData: [],
    // allData: [],
    total: 0,
    // page: 1,
    // limit: 10,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.currentData = action.payload.data;
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.details = action.payload;
      })
      .addCase(getCurrentRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
    
    builder
      .addCase(createCurrentRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCurrentRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.allData = action.payload.result || [];
      })
      .addCase(createCurrentRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
    
    builder
      .addCase(getSingleRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload.result || [];
      })
      .addCase(getSingleRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
    
  },
});

export default currentRequestSlice.reducer;
