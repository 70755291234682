import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/input-search-icon.svg";
import MoreIcon from "../../../assets/more-icon.svg";
import DataTable from "../../../shared/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRequest } from "../../../services/currentRequest";
import { MenuButton, MenuItems, MenuItem, Menu } from "@headlessui/react";
import { useNavigate } from "react-router";
import AddPartnersInCategories from "../../Popups/AddPartnersInCategories";
import ViaIcon from "../../../assets/via.svg";
import OptIcon from "../../../assets/opt.svg";
import moment from "moment";



export default function AdminRequest() {
  const [actionTab, setActiveTab] = useState("All");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const actionTabFilter = [
    {
      key: "all",
      name: "All",
    },
    {
      key: "new",
      name: "new",
    },
    {
      key: "in-progress",
      name: "in progress",
    },
    {
      key: "completed",
      name: "completed",
    },
    {
      key: "on-hold",
      name: "on hold",
    },
    {
      key: "rejected",
      name: "rejected",
    },
  ];

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const Column = [
   
    {
      field: "opt",
      headerName: "OPT",
      flex: 1,
      renderHeader: () => (
        <div className="flex items-center gap-[5px]">
          <p className="font-sans text-[#18283D] font-semibold">OPT</p>
          <img src={OptIcon} alt="opt" />
        </div>
      ),
    },
      {
        field: "id",
        headerName: "Request ID",
        flex: 1,
      },
      {
        field: "first_name",
        headerName: "Client Name",
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Contact",
        flex: 1,
      },
      {
        field: "request_via",
        headerName: "Via",
        flex: 1,
        renderHeader: () => (
          <div className="flex items-center gap-3">
            <p className="font-sans text-[#18283D] font-semibold">Via</p>
            <img src={ViaIcon} alt="via" />
          </div>
        ),
        renderCell: (params) => (
          <div className="capitalize">{params.value}</div>
        )
      },
    
      {
        field: "intakeDate",
        headerName: "Intake Date & Time ",
        flex: 2,
        renderCell: (params) => {
          return moment(params?.row?.created_at).format("MM/DD/YYYY | h:mm A");
        },
      },
      {
        field: "level",
        headerName: "Level",
        flex: 1,
        renderCell: (params) => (
          <div className="capitalize">{params.value}</div>
        )
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
      },
      {
        field: "submittedDate",
        headerName: "Submitted Date & Time",
        flex: 2,
        renderCell: (params) => {
          return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
        },
      },
      {
        field: "request_status",
        headerName: "Status",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (params) => {
          const colors = {
            "in progress": "text-[#FFCD11]",
            completed: "text-[#278E1D]",
            wait: "text-[#1D5BBF]",
            "on hold": "text-[#E26313]",
            rejected: "text-[#DC3545]",
            new: "text-[#1D5BBF],"
          };
          return (
            <div className={`capitalize ${colors[params.value] || ""}`}>
              {params.value}
            </div>
          );
        },
      },
      {
        field: "partners",
        headerName: "Partner",
        flex: 1,
      },
      {
        field: "response",
        headerName: "Partner Response",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (params) => {
          const colors = {
            "in progress": "text-[#FFCD11]",
            accepted: "text-[#278E1D]",
            wait: "text-[#1D5BBF]",
            "on hold": "text-[#E26313]",
            rejected: "text-[#DC3545]",
            new: "text-[#1D5BBF]," 
          };
          return (
            <div className={`capitalize ${colors[params.value] || ""}`}>
              {params.value}
            </div>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        headerAlign: "center",
        align: "center",
        flex: 1,
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <>
              <Menu as="div" className="inline-block mt-2 text-left">
                <div>
                  <MenuButton
                    onClick={(e) => handleClick(e, params.row)}
                    className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900"
                  >
                    <img src={MoreIcon} alt="MoreIcon" />
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem
                      onClick={() => {
                        navigate(`/partner/${params.id}`);
                      }}
                    >
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        View
                      </a>
                    </MenuItem>
                    {/* {status === 'new' && */}
                    <MenuItem
                      onClick={() => {
                        setOpen(!open);
                        setId(params.row.id);
                      }}
                    >
                      <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        {" "}
                        Select Partner
                      </div>
                    </MenuItem>
                    {/* } */}
                  </div>
                </MenuItems>
              </Menu>
            </>
          );
        },
      },
    ];
    
   
  

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("hitted");
    dispatch(
      getCurrentRequest({
        request_via: "",
        status: "",
      })
    );
  }, [dispatch]);

  const currentData = useSelector((state) => state.currentRequest.currentData);
  // const total = useSelector((state) => state.currentRequest.total);

  const rows =
    currentData && currentData.length > 0
      ? currentData.map((item) => ({
        ...item,
          id: item.id,
          requestId: item.id,
          name: item.first_name,
          category: item.id,
          contact: item.contact,
          via: item.request_via,
          intakeDate: item.created_at,
          level: item.urgency_level,
          description: item.request_status_desc,
          submittedDate: item.updated_at,
          status: item.request_status,
          response: item.partner_status,
        }))
      : [];
     

  return (
    <>
      <div className="flex justify-between pb-4">
        <div>Request</div>
      </div>

      <div
        className="px-3 py-5 mb-5"
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <div className="details-info">
          <div
            className="flex items-center justify-between px-5 mb-8 "
            style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
          >
            <ul className="tab-list">
              {actionTabFilter?.map((discussion, index) => (
                <li
                  className={`${
                    actionTab === discussion.name ? "active" : ""
                  } relative`}
                  key={index}
                  onClick={() => setActiveTab(discussion.name)}
                >
                  <div className="flex justify-center pb-1">
                    <div
                      className={`total-proram-count relative ${
                        actionTab === discussion.key ? "active" : ""
                      }`}
                    >
                      {rows.filter((data) =>
                        discussion.name !== "All"
                          ? data?.status === discussion.name
                          : true
                      )?.length || 0}
                    </div>
                  </div>
                  <div className="text-[13px]"> {`${discussion.name}`}</div>
                  {actionTab === discussion.key && <span></span>}
                </li>
              ))}
            </ul>

            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-sm text-gray-900 border-none"
                placeholder="Search here..."
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  height: "50px",
                  width: "390px",
                }}
              />
              <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className="px-3">
          <DataTable
            rows={
              rows.filter((data) =>
                actionTab !== "All" ? data?.status === actionTab : data
              ) || []
            }
            columns={Column}
            // total={total}
            hideCheckbox
            hideFooter
          />
        </div>
      </div>
      <AddPartnersInCategories
        open={open}
        setOpen={setOpen}
        id={id}
        setId={setId}
      />
    </>
  );
}
